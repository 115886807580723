import { EServiceType, IService } from "ts/interfaces/services/service";
import Pdf2PngIcon from 'assets/converterIcons/pdf_2_png.svg';

export const mainPageService = (t: any): IService => {
  return {
    serviceType: EServiceType.CONVERTOR,
    path: '/pdf-to-png',
    name: t('main_page.name'),
    nameKey: 'main_page.name',
    title: t('main_page.title'),
    description: t('main_page.description'),
    aboutSection: {
      title: t('main_page.aboutSection.title'),
      items: [
        t('main_page.aboutSection.items.0'),
        t('main_page.aboutSection.items.1'),
        t('main_page.aboutSection.items.2'),
      ],
    },
    ctaTitle: t('main_page.secure_section_title'),
    meta: {
      title: t('main_page.meta.title'),
      description: t('main_page.meta.description'),
    },
    icon: Pdf2PngIcon,
    from: 'PDF',
    to: 'PNG',
    availableFormats: '.pdf',
  };
};