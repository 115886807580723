import CommonButton from 'components/common/button';
import styled from 'styled-components';

export const HeroButtonWithAnimation = styled.button`
  width: 297px;
  height: 70px;
  border-radius: 5px;
  border: 1px solid var(--Primary-primary-50, #3758f9);
  background: var(--Primary-Color, #3758f9);
  color: var(--White-White, #fff);
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  cursor: pointer;
  margin-bottom: 20px;
  display: inline-block;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
  z-index: 1;

  span {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      height: 5px;
      transition: all 0.5s linear;
      width: 0;
    }
  }

  &::before,
  &::after {
    width: 0;
    content: '';
    position: absolute;
    background-color: #1530b5;
    top: 0;
    bottom: 0;
    z-index: -1;
    transition: all 0.5s ease-in-out;
  }

  &::before {
    left: 50%;
    right: auto;
  }

  &::after {
    right: 50%;
    left: auto;
  }

  &:hover {
    &::before,
    &::after {
      width: 50%;
    }

    span {
      &::after {
        position: absolute;
        left: 50%;
        content: '';
        height: 2px;
        transition: all 0.5s linear;
        width: 40px;
        bottom: -10px;
        border-bottom: 2px solid #fff;
      }
    }
  }

  @media (max-width: 760px) {
    font-size: 16px;
    min-width: 220px;
    width: auto;
  }
`;

export const HeroButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 297px;
  height: 70px;
  border-radius: 5px;
  border: 1px solid var(--Primary-primary-50, #3758f9);
  background: var(--Primary-Color, #3758f9);
  color: var(--White-White, #fff);
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  cursor: pointer;
  margin-bottom: 20px;
  position: relative;

  .lottie-svg-button-upload {
    border-radius: 5px;
    position: absolute;
    left: 0;
  }

  span {
    position: absolute;
  }

  .mobile {
    display: none;
  }

  @media (max-width: 760px) {
    display: flex;
    width: 209px;
    padding: 15px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--White-White, #fff);
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 20px */

    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
`;

export const Container = styled.div`
  height: 70px;
  .mobile {
    display: none;
  }

  .ant-upload-drag {
    border: none !important;
    background: none !important;

    .ant-upload {
      padding: 0 !important;
    }
  }

  .disable-drag-drop .ant-upload-drag {
    display: none;
  }

  @media (max-width: 760px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
`;

export const AnimationContainer = styled.div`
  height: 70px;
  width: 324px;
`;

export const ButtonStyles: any = {
  width: '326px',
  height: '70px',
  borderRadius: '5px',
  textAlign: 'center',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '125%',
  cursor: 'pointer',
  marginBottom: '20px',
};

export const MobileButton = styled(CommonButton)`
  width: max-content !important;
  min-width: 190px !important;
  height: 70px;
  border-radius: 5px;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  cursor: pointer;
  margin-bottom: 20px;
`;
