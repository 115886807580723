import closeImagePath from 'assets/close-icon.svg';
import SelectPlanModalContentB from 'components/pricing/tests/abc-modal_sales_payment_2_2_4/pricingTestB';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { CloseIcon, ModalContainer } from '../baseModal/styles';
import { Container } from './styles';

const StyledModalContainer = styled(Container)``;

const SelectPlanModalB = () => {
  const dispatch = useDispatch();
  useCloseModalOnBack();

  const handleCloseModal = useCallback(() => {
    dispatch(sendAnalyticEvent({ event: 'select_plan_close_tap' }));
    dispatch(toggleModal({ visible: false }));
  }, [dispatch]);

  return (
    <ModalContainer>
      <StyledModalContainer>
        <CloseIcon onClick={handleCloseModal} src={closeImagePath} alt="" />
        <SelectPlanModalContentB />
      </StyledModalContainer>
    </ModalContainer>
  );
};

export default SelectPlanModalB;
