import { useEffect, useRef } from 'react';

// Interfaces
import { IFormService } from 'ts/interfaces/services/service';

// Components
import FaqSection from '../faq';

// Styles
import {
  AboutItemContainer,
  AboutItemContent,
  AboutItemDescription,
  AboutItemNumberIcon,
  ContentColumn,
  ContentContainer,
  ContentItem,
  ContentItemDescription,
  ContentItemTitle,
  FeatureContainer,
  ContentWrapper,
  FeatureIcon,
  FeatureItemImageContainer,
  StepsContainer,
  FeatureDescription,
} from './styles';

interface IFormServicePageProps {
  service: IFormService;
  setIsFixedBox: (value: boolean) => void;
}

const FormContent = ({
  service,
  setIsFixedBox,
}: IFormServicePageProps) => {
  const targetRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (targetRef?.current) {
        const rect = targetRef.current.getBoundingClientRect();

        const isAboveScreen = rect.bottom <= 0;
        const isOnScreen = rect.top <= window.innerHeight && rect.bottom >= 0;
        const isAtBottom =
          window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;

        // Hide target div if scroll is on first screen or at the bottom
        setIsFixedBox((isOnScreen || isAboveScreen) && !isAtBottom);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [targetRef, setIsFixedBox]);

  return (
    <ContentContainer>
      <ContentWrapper>
        <ContentColumn>
          <ContentItem>
            <ContentItemTitle id="what-is-section" ref={targetRef}>
              {service.whatIsSection?.title}
            </ContentItemTitle>
            <ContentItemDescription>{service.whatIsSection?.text}</ContentItemDescription>
          </ContentItem>

          <ContentItem>
            <ContentItemTitle id="who-is-section">
              {service.whoIsSection?.title}
            </ContentItemTitle>
            {service.whoIsSection?.items.map((text, idx) => (
              <ContentItemDescription key={idx}>{text}</ContentItemDescription>
            ))}
          </ContentItem>

          <ContentItem>
            <ContentItemTitle id="how-to-section">{service.howToSection?.title}</ContentItemTitle>
            <FeatureContainer>
              <FeatureItemImageContainer background={service.howToSection?.background}>
                <FeatureIcon
                  data={service.howToSection?.icon}
                  type="image/svg+xml"
                  width="120px"
                  height="120px"
                />
              </FeatureItemImageContainer>
              <FeatureDescription>
                <StepsContainer>
                  {service.howToSection?.items.map((step, idx) => (
                    <AboutItemContainer key={`${idx + 1}`}>
                      <AboutItemNumberIcon active={'true'}>{idx + 1}</AboutItemNumberIcon>
                      <AboutItemContent>
                        <AboutItemDescription 
                        dangerouslySetInnerHTML={{__html: step}}
                        ></AboutItemDescription>
                      </AboutItemContent>
                    </AboutItemContainer>
                  ))}
                </StepsContainer>
              </FeatureDescription>
            </FeatureContainer>
          </ContentItem>
          <FaqSection service={service} />
        </ContentColumn>
      </ContentWrapper>
    </ContentContainer>
  );
};

export default FormContent;
