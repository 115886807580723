import { Upload } from 'antd';
import { FC, useMemo, useState } from 'react';

import CommonButton from 'components/common/button';
import openNotification from 'components/commonNotification';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { getFileFormatFromFile } from 'helpers/getFileFormatFromFile';
import { mergePageService } from 'helpers/services/servicesList';
import { validateFile } from 'helpers/validation';
import useFileUploadAndConvertEditorFlow from 'hooks/editor-flow/useFileUploadAndConvert';
import { useCurrentLanguage } from 'hooks/useCustomNavigate';
import useFileUploadAndMerge from 'hooks/useFileUploadAndMerge';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ENotification } from 'ts/interfaces/common/notification';
import { IService } from 'ts/interfaces/services/service';
import { formatFileSize } from 'utils/formatFileSize';
import useFileUploadAndConvert from '../../../hooks/useFileUploadAndConvert';
import { ButtonStyles, Container, MobileButton } from './styles';

export const FILE_TYPES_NOT_FOR_EDITOR_FLOW = [
  '.docx',
  '.doc',
  '.pptx',
  '.ppt',
  '.xls',
  '.xlsx',
  '.csv',
  '.txt',
  '.rtf',
  '.ods',
  '.odt',
  '.epub',
  '.pages',
  '.heic',
  '.jfif',
];

const UploadButton: FC<{
  service?: IService;
  buttonText?: string;
  isFooter?: boolean;
  isReversedText?: boolean;
}> = ({
  service,
  isFooter,
  buttonText,
  isReversedText,
}: {
  service?: IService;
  buttonText?: string;
  isFooter?: boolean;
  isReversedText?: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleUploadFile } = useFileUploadAndConvert({ service });
  const { handleUploadImagesFiles } = useFileUploadAndMerge({
    service: mergePageService(t),
  });
  const { handleUploadFile: handleUploadFileEditorFlow } = useFileUploadAndConvertEditorFlow({
    service,
  });

  const isMultiplyField = service?.path.split('/')[1] === 'image-to-pdf';
  const locale = useCurrentLanguage();

  // HACK: Crutch to localize text only for Japanese lang
  const fileTypeLocalized = useMemo(() => {
    if (locale === 'ja' && service?.from.toLowerCase() === 'image') {
      return '画像を';
    } else {
      return service?.from || 'PDF';
    }
  }, [locale, service?.from]);

  const uploadFile = (file: File) => {
    if (
      !getFileFormatFromFile(file) ||
      FILE_TYPES_NOT_FOR_EDITOR_FLOW.includes(getFileFormatFromFile(file)!.toLowerCase())
    ) {
      return handleUploadFile(file);
    }
    handleUploadFileEditorFlow(file);
  };

  const [disabled, setDisabled] = useState(false);
  const [filesArrayToUpload, setFilesArrayToUpload] = useState<File[]>([]);

  const handleBeforeUpload = (file: File) => {
    const isErrorMessage = validateFile(file, t, service?.availableFormats);

    if (isErrorMessage) {
      openNotification({
        message: isErrorMessage,
        type: ENotification.ERROR,
      });
      dispatch(
        sendAnalyticEvent({
          event: 'file_upload_status',
          data: {
            status: 'fail',
            place: 'main',
            errorCode: (file?.size || 0) > 100 * 1024 * 1024 ? 'max-size' : 'wrong-format',
            size: formatFileSize(file?.size || 0),
            accurate_size: (file?.size || 0) / 1000000,
            fileCounter: 1,
            file_format: `.${file?.name?.split('.')?.pop()}`,
            is_validation_error: 'true',
          },
        })
      );
      return false;
    }
    return true;
  };

  // disable drag & drop
  const handleOnDragOver = () => {
    if (!disabled) setDisabled(true);
  };

  // disable drag & drop
  const handleOnMouseOver = () => {
    if (disabled) setDisabled(false);
  };

  return (
    <Container
      onDragOver={() => handleOnDragOver()}
      onMouseOver={() => handleOnMouseOver()}
      onClick={() =>
        dispatch(
          sendAnalyticEvent({
            event: isFooter ? 'upload_ﬁle_footer_tap' : 'upload_ﬁle_tap',
          })
        )
      }
    >
      <Upload
        type="select"
        multiple={isMultiplyField}
        disabled={disabled}
        accept={service?.availableFormats}
        name="file"
        showUploadList={false}
        customRequest={(file: any | File[]) => {
          if (isMultiplyField && filesArrayToUpload.length > 1) {
            handleUploadImagesFiles(filesArrayToUpload);
          } else {
            uploadFile(file.file);
          }
        }}
        beforeUpload={(file, fileList) =>
          fileList.length > 1 ? setFilesArrayToUpload(fileList) : handleBeforeUpload(file)
        }
      >
        <CommonButton width="324px" className="desktop" style={ButtonStyles} type="primary">
          {/* {buttonText || t('global.file_upload_button')} */}
          {buttonText ||
            (isReversedText
              ? `${fileTypeLocalized} ${t('global.upload')}`
              : `${t('global.upload')} ${fileTypeLocalized}`)}
        </CommonButton>

        <MobileButton width="324px" className="mobile" style={ButtonStyles} type="primary">
          {buttonText || t('global.file_upload_button_mobile')}
        </MobileButton>
      </Upload>
    </Container>
  );
};

export default UploadButton;
