import { CommonTextH2, CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div<{ current?: string; mostPopular?: string }>`
  max-width: 320px;
  width: 100%;
  display: flex;
  position: relative;
  padding: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 15px;
  background: var(--Primary-white, #fff);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  border: ${(props) => (props?.current === 'true' ? '4px solid #3758F9' : '4px solid transparent')};
  margin-right: 30px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;

    @media (max-width: 760px) {
      margin: 0 auto;
    }
  }

  @media (max-width: 1080px) {
    padding: var(--Radius-3xl, 50px) var(--Radius-l, 20px);
  }

  @media (max-width: 760px) {
    margin-right: 0;
    padding: 20px;
    margin: 0 auto;
  }
`;

export const PlanContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  position: relative;
`;

export const PlanHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

export const PlanSubtitle = styled.div`
  color: var(--Text-color-subtitle, #4c5e7f);
  /* Desktop/Body/Reg */

  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const PlanTitle = styled(CommonTextH5)`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  // gap: 15px;

  span {
    color: var(--Text-color-tittle, #212e45);

    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  img {
    height: 25px;
    width: 25px;
  }

  @media (max-width: 760px) {
    align-items: flex-start;
  }
`;

export const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
`;

export const Price = styled(CommonTextH2)`
  color: var(--Text-color-tittle, #212e45);

  font-size: 33px;
  font-weight: 900;
  line-height: 39.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  @media (max-width: 760px) {
    font-size: 28px;
    margin: 10px 0;
  }
`;

export const Currency = styled.span`
  color: var(--80, #354562);
  /* Desktop/Heading/H5/Bold */

  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 5px;
`;

export const Period = styled.span`
  color: var(--Text-color-subtitle, #4c5e7f);
  /* Desktop/Small Body/Reg */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  margin-left: 5px;
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FeatureItem = styled.span`
  color: var(--Text-color-subtitle, #4c5e7f);

  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  display: flex;
  align-items: start;
`;

export const FeatureIcon = styled.img`
  margin-right: 10px;
  margin-top: 2px;
`;

export const MostPopular = styled.div`
  min-width: 114px;
  max-width: fit-content;
  width: 100%;
  height: 28px;
  display: flex;
  padding: var(--Radius-m, 15px) var(--Radius-s, 10px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Radius-s, 10px);
  align-self: stretch;
  color: var(--Blue-white, #fff);
  border-radius: 20px;

  background-color: #3758f9;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 18.2px */
`;

export const MostPopularContainer = styled.div`
  position: absolute;
  top: -17px;
  z-index: 1000;
  display: flex;
  justify-content: start;
  width: calc(100% + 8px);
  left: -12px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

export const PlanTitleContainer = styled.div`
  position: relative;
  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
  }
`;
