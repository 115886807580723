import styled from 'styled-components';

export const SectionWrapper = styled.div`
  margin: 100px 0;

  @media (max-width: 760px) {
    margin: 40px 0;
  }
`;

export const Container = styled.div`
  height: auto;

  h1, h2 {
    font-family: 'Arial Black', Arial, sans-serif;
  }
`;