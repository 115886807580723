import CommonLink from 'components/common/link';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 50px 25px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--Blue-100, #000e4f);

  @media (max-width: 780px) {
    padding: 30px 15px;
  }
`;

export const DesktopContainer = styled.div`
  display: flex;
  gap: 30px;
  max-width: 1170px;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 780px) {
    display: none;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #c9d8f4;
  opacity: 0.3;
  margin: 30px 0;
`;

export const CustomExpandIcon = styled.img<{ $is_active?: boolean | string }>`
  transform: rotate(${(props) => (props.$is_active === 'true' ? '180deg' : '0')});
`;

export const MobileContainer = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;

  .ant-collapse {
    padding: 0 40px;
  }

  .ant-collapse-header {
    display: flex !important;
    align-items: center !important;
    padding: 0 !important;
  }

  .ant-collapse-header-text {
    color: var(--Color, #fff);

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    height: 40px;
    display: flex;
    align-items: center;
  }

  .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 !important;
    margin-top: 15px;
  }

  @media (max-width: 780px) {
    display: flex;
  }
`;

export const SectionTitle = styled.span`
  color: var(--Blue-20, #bec9ff);

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  text-transform: uppercase;
`;

export const LinksGroupTitle = styled.span`
  color: var(--Color, #fff);
  width: max-content;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  text-transform: uppercase;
`;

export const LinkItem = styled(CommonLink)`
  color: var(--Primary-10, #ebeeff);
  /* Body/Reg */

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
`;

export const MobileLinkItem = styled(LinkItem)`
  color: var(--Color, #fff) !important;
  /* Body/Reg */

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  padding: 0 20px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 270px;
  width: 100%;
`;

export const LinksGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 860px;
  gap: 15px;
`;

export const Text = styled.div`
  color: var(--Blue-20, #bec9ff);
  /* Body/Reg */

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
`;
