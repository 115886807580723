import { FC } from 'react';
import {
  Container,
  SectionTitle,
  FeatureItemContainer,
  FeatureItemImageContainer,
  FeatureItemLabel,
  FeatureItemDescription,
  FeaturesContainer,
  FeatureIcon,
  Content,
} from './styles';
import { IFormService } from 'ts/interfaces/services/service';

const FeaturesSection: FC<{ service?: IFormService }> = ({ service }: { service?: IFormService }) => {

  return (
    <Container>
      <Content>
        <SectionTitle>{service?.aboutSection?.title}</SectionTitle>

        <FeaturesContainer>
          {service?.aboutSection?.items.map((feature, idx) => (
            <FeatureItemContainer key={idx}>
              <FeatureItemImageContainer background={feature.color}>
                <FeatureIcon
                  data={feature.icon}
                  type="image/svg+xml"
                  width="120px"
                  height="120px"
                />
              </FeatureItemImageContainer>
              <FeatureItemLabel>
                {`${idx + 1}. ${feature.label}`}
              </FeatureItemLabel>
              <FeatureItemDescription>
                {feature.description}
              </FeatureItemDescription>
            </FeatureItemContainer>
          ))}
        </FeaturesContainer>
      </Content>
    </Container>
  );
};

export default FeaturesSection;