import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TABLET_SCREEN_SIZE_WIDTH } from 'ts/constants/general';
import { TrustPilotContainer } from './styles';

declare global {
  interface Window {
    Trustpilot: any;
  }
}

const TrustPilotCarousel = () => {
  const trustPilotRef = useRef(null);
  const { i18n } = useTranslation();
  const isMobile = window.innerWidth <= TABLET_SCREEN_SIZE_WIDTH;

  useEffect(() => {
    if (window?.Trustpilot) {
      window?.Trustpilot.loadFromElement(trustPilotRef.current, true);
    }
  }, []);

  return (
    <TrustPilotContainer isMobile={isMobile.toString()}>
      <div
        className="trustpilot-widget"
        data-locale={i18n.language}
        data-template-id="5419b6ffb0d04a076446a9af"
        data-businessunit-id="665dc33d33b0a29220f1d82a"
        data-style-height="43px"
        data-style-width="200px"
        data-theme="light"
        data-stars="5"
        data-review-languages={i18n.language}
        ref={trustPilotRef}
      >
        <a
          href="https://www.trustpilot.com/review/thebestpdf.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </TrustPilotContainer>
  );
};

export default TrustPilotCarousel;
