import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCustomNavigate } from 'hooks/useCustomNavigate';
//** Selectors */
import { selectedPlanSelector } from 'data/selectors/subscriptions';

//** Components */

/** Types */
import { IPlan } from 'ts/interfaces/plan/plan';

/** Actions */
import { getFreeAccess, setSelectedPlan } from 'data/actions/subscriptions';

//** Icons */

//** Styles */
import CommonButton from 'components/common/button';
import PlanItemTestC from 'components/planItem/tests/abc-modal_sales_payment_2_2_4/planItemTestC';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import useKeyDownPress from 'hooks/useKeyPress';
import { usePlans } from 'hooks/usePlans';
import { useUploadFile } from 'hooks/useUploadFile';
import { EModalTypes } from 'ts/enums/modal.types';
import {
  Container,
  Description,
  DescriptionContainer,
  DialogLeftContainer,
  DialogTitle,
  PlansContainer,
  TitleContainer,
} from './styles';

const SelectPlanModalContentC: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedPlan = useSelector(selectedPlanSelector) || getJsonFomStorage('selectedPlan');
  const plans = usePlans();
  const navigate = useCustomNavigate();

  useUploadFile(true);

  useEffect(() => {
    dispatch(
      sendAnalyticEvent({
        event: 'select_plan_view',
        data: { screen_config_name: 'sales_modal_3_plans_button_top' },
      })
    );
  }, [dispatch]);

  const handleSelectPlan = ({
    plan,
    route,
    place = 'button',
  }: {
    plan: IPlan;
    route?: string;
    place: 'planTab' | 'button';
  }) => {
    if (selectedPlan?.name === plan?.name) {
      dispatch(
        sendAnalyticEvent({
          event: 'select_plan_confirm_tap',
          data: { planName: t(plan?.name), place },
        })
      );
      handleSubmit();
      return;
    }
    localStorage.setItem('selectedPlan', JSON.stringify(plan));
    dispatch(setSelectedPlan(plan));
    dispatch(
      sendAnalyticEvent({
        event: 'choose_plan_tap',
        data: {
          productId: plan?.slagName || plan?.id,
          currency: plan?.currency,
          value: plan?.fullPrice,
        },
      })
    );
  };

  useKeyDownPress({
    targetKey: 'Enter',
    onKeyPressDown: () => {
      handleSubmit();
    },
  });

  useEffect(() => {
    if (!selectedPlan) {
      dispatch(setSelectedPlan(plans[1] || plans[0]));
    }
  }, [dispatch, plans, selectedPlan]);

  const handleSubmit = () => {
    dispatch(toggleModal({ type: EModalTypes.PAYMENT_MODAL, visible: true }));
  };

  const handleFreeAccess = () => {
    dispatch(sendAnalyticEvent({ event: 'give_free_access_tap' }));
    const onSuccess = () => {
      navigate('/app/dashboard');
      dispatch(
        toggleModal({
          type: EModalTypes.GET_FREE_ACCESS,
          visible: true,
          options: { status: 'success ' },
        })
      );
    };

    const onFail = () => {
      dispatch(
        toggleModal({
          type: EModalTypes.GET_FREE_ACCESS,
          visible: true,
          options: { status: 'error' },
        })
      );
    };

    dispatch(sendAnalyticEvent({ event: 'give_free_access_tap' }));
    dispatch(getFreeAccess(onSuccess, onFail));
  };

  return (
    <Container>
      <DialogLeftContainer>
        <TitleContainer>
          <DialogTitle>{t('choose_plan_page.title')}</DialogTitle>
          <CommonButton
            style={{ width: '187px' }}
            onClick={() => handleSelectPlan({ plan: selectedPlan, place: 'button' })}
            type="primary"
          >
            {t('global.continue')}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M18 9.5L11.5312 2.9375C11.25 2.65625 10.8125 2.65625 10.5312 2.9375C10.25 3.21875 10.25 3.65625 10.5312 3.9375L15.7812 9.28125H2.5C2.125 9.28125 1.8125 9.59375 1.8125 9.96875C1.8125 10.3438 2.125 10.6875 2.5 10.6875H15.8437L10.5312 16.0938C10.25 16.375 10.25 16.8125 10.5312 17.0938C10.6562 17.2188 10.8437 17.2813 11.0312 17.2813C11.2187 17.2813 11.4062 17.2188 11.5312 17.0625L18 10.5C18.2812 10.2188 18.2812 9.78125 18 9.5Z"
                fill="white"
              />
            </svg>
          </CommonButton>
        </TitleContainer>

        <PlansContainer>
          {plans?.map((plan: IPlan, index: number) => (
            <PlanItemTestC
              handleSelectPlan={handleSelectPlan}
              key={`plan-item-${index + 1}`}
              planData={plan}
            />
          ))}
        </PlansContainer>
        <DescriptionContainer>
          <Description>
            {t(selectedPlan?.descriptionSales, {
              currencySymbol: selectedPlan?.currencySymbol,
              fullPrice: selectedPlan?.fullPrice,
            })}
            {t('global.to_access_your_first_document_start')}{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={handleFreeAccess}
            >
              {' '}
              {t('global.click_here')}
            </span>
            {t('global.to_access_your_first_document_end')}
          </Description>
        </DescriptionContainer>
      </DialogLeftContainer>
    </Container>
  );
};

export default SelectPlanModalContentC;
