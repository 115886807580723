import { FC } from 'react';

import {
  Container,
  Title,
  FaqItemTitle,
  FaqItemDescription,
  FaqItem,
  FaqContainer,
  Divider,
} from './styles';
import { IFormService } from 'ts/interfaces/services/service';

interface IFormServicePageProps {
  service: IFormService;
}

const FaqSection: FC<IFormServicePageProps> = ({ service }) => {

  return (
    <Container>
      <Title id="faq-section">{service?.faqSection?.title}</Title>
      <FaqContainer>
        {service?.faqSection?.items?.map((item, index: number) => (
          <div key={`faq-item-${index}`}>
            <FaqItem>
              <FaqItemTitle>{item.title}</FaqItemTitle>
              <FaqItemDescription>{item.text}</FaqItemDescription>
            </FaqItem>
            {index !== 3 && <Divider />}
          </div>
        ))}
      </FaqContainer>
    </Container>
  );
};

export default FaqSection;
