import { useFeature } from '@growthbook/growthbook-react';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { GrowthBookTestType, LocalStorageProperties } from 'ts/enums/growthbook';

const UNKNOWN_FEATURE = 'unknownFeature';

export const useModalSalesPayment = () => {
  const abTests = getJsonFomStorage('abTests');
  const testingmodalSalesPAymentABC = useFeature(GrowthBookTestType.MODAL_SALES_PAYMENT);

  if (
    testingmodalSalesPAymentABC?.off === true &&
    testingmodalSalesPAymentABC.source !== UNKNOWN_FEATURE
  )
    return LocalStorageProperties.MODAL_SALES_PAYMENT_A as const;

  if (abTests?.includes(LocalStorageProperties.MODAL_SALES_PAYMENT_B))
    return LocalStorageProperties.MODAL_SALES_PAYMENT_B as const;
  else if (abTests?.includes(LocalStorageProperties.MODAL_SALES_PAYMENT_C))
    return LocalStorageProperties.MODAL_SALES_PAYMENT_C as const;

  if (
    testingmodalSalesPAymentABC?.experiment?.key &&
    testingmodalSalesPAymentABC?.experimentResult?.value
  ) {
    return `${testingmodalSalesPAymentABC?.experiment?.key}_${testingmodalSalesPAymentABC?.experimentResult?.value}` as
      | LocalStorageProperties.MODAL_SALES_PAYMENT_A
      | LocalStorageProperties.MODAL_SALES_PAYMENT_B
      | LocalStorageProperties.MODAL_SALES_PAYMENT_C;
  }

  return LocalStorageProperties.MODAL_SALES_PAYMENT_A as const;
};
