import closeImagePath from 'assets/close-icon.svg';
import PaymentFormTestB from 'components/paymentForm/tests/abc-modal_sales_payment_2_2_4/paymentFormTestB';
import { GoBackButton } from 'components/paymentForm/tests/abc-modal_sales_payment_2_2_4/paymentFormTestB/styles';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import { useModalSalesPayment } from 'hooks/growthBook/useModalSalesPayment';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LocalStorageProperties } from 'ts/enums/growthbook';
import { EModalTypes } from 'ts/enums/modal.types';
import { ModalContainer } from '../baseModal/styles';
import { CloseIcon, DialogHeadRow, ModalHeader, StyledModalContainer } from './styles';

const PaymentModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modalSalesPayment = useModalSalesPayment();
  const isBVariant = useMemo(
    () => modalSalesPayment === LocalStorageProperties.MODAL_SALES_PAYMENT_B,
    [modalSalesPayment]
  );

  useCloseModalOnBack();

  const handleCloseModal = useCallback(() => {
    dispatch(sendAnalyticEvent({ event: 'payment_close_tap' }));
    dispatch(toggleModal({ visible: false }));
  }, [dispatch]);

  return (
    <ModalContainer onClick={() => !isBVariant && handleCloseModal()}>
      <StyledModalContainer onClick={(e) => e.stopPropagation()}>
        <DialogHeadRow>
          <GoBackButton
            onClick={() => {
              dispatch(sendAnalyticEvent({ event: 'back_to_select_plan_tap' }));
              dispatch(
                toggleModal({
                  visible: true,
                  type: isBVariant
                    ? EModalTypes.MODAL_SELECT_PLAN_B
                    : EModalTypes.MODAL_SELECT_PLAN_C,
                })
              );
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path d="M18 9.5L11.5312 2.9375C11.25 2.65625 10.8125 2.65625 10.5312 2.9375C10.25 3.21875 10.25 3.65625 10.5312 3.9375L15.7812 9.28125H2.5C2.125 9.28125 1.8125 9.59375 1.8125 9.96875C1.8125 10.3438 2.125 10.6875 2.5 10.6875H15.8437L10.5312 16.0938C10.25 16.375 10.25 16.8125 10.5312 17.0938C10.6562 17.2188 10.8437 17.2813 11.0312 17.2813C11.2187 17.2813 11.4062 17.2188 11.5312 17.0625L18 10.5C18.2812 10.2188 18.2812 9.78125 18 9.5Z" />
            </svg>
          </GoBackButton>
          <ModalHeader>{t('global.last_step_to_access_your_file')}</ModalHeader>
          {isBVariant ? (
            <CloseIcon onClick={handleCloseModal} src={closeImagePath} alt="" />
          ) : (
            <span></span>
          )}
        </DialogHeadRow>
        <PaymentFormTestB />
      </StyledModalContainer>
    </ModalContainer>
  );
};

export default PaymentModal;
