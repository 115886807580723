import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 100px auto;
  align-self: stretch;

  @media (max-width: 760px) {
    display: flex;
    margin: 40px auto;
    align-items: baseline;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  width: 100%;
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  margin: 0 15px;
  gap: 50px;

  @media (max-width: 880px) {
    padding: 0 15px;
  }

  @media (max-width: 760px) {
    padding: 0 15px;
    gap: 40px
  }
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentItemTitle = styled.h2`
  color: var(--Text-color-tittle, #212e45);
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 120%;
  margin-bottom: 50px;
  text-align: center;

  @media (max-width: 760px) {
    //margin-top: 20px;
    margin-bottom: 20px;
    font-size: 23px;
  }
`;

export const ContentItemDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 22px;
  color: var(--Text-color-subtitle, #4c5e7f);
  align-self: stretch;

  @media (max-width: 760px) {
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 0;
  }
`;

export const AboutItemContainer = styled.div`
  display: flex;

  @media (max-width: 1080px) {
    align-items: flex-start;
  }
`;

export const AboutItemDescription = styled.span`
  align-self: stretch;
  color: var(--Text-color-subtitle, #4c5e7f);

  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 22px;

  @media (max-width: 1080px) {
    font-size: 16px;
  }
`;

export const AboutItemNumberIcon = styled.div<{ active?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid var(--Primary-primary-50, #3758f9);
  background: ${(props) =>
    props?.active === 'true' ? 'var(--Primary-primary-50, #3758F9)' : 'var(--Primary-white, #FFF)'};
  text-align: center;
  color: ${(props) =>
    props?.active === 'true' ? 'var(--Primary-white, #FFF)' : 'var(--Primary-primary-50, #3758F9)'};
  /* Heading/H5 */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;

  @media (max-width: 1080px) {
    font-size: 16px;
  }
`;

export const AboutItemContent = styled.div`
  margin-left: 15px;
  flex: 1 0 0;
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 888px) {
    padding: 0;
    gap: 15px;
  }
`;

export const FeatureContainer = styled.div`
  display: flex;
  gap: 50px;

  @media (max-width: 760px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const FeatureDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 22px;
  color: var(--Text-color-subtitle, #4c5e7f);
  align-self: stretch;
  flex: 1;

  @media (max-width: 760px) {
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 0;
  }
`;

export const FeatureItemImageContainer = styled.div<{ background?: string }>`
  max-width: 350px;
  max-height: 350px;
  flex-shrink: 0;
  border-radius: 10px;
  background: ${(props) => props?.background || 'var(--Primary-primary-50, #3758F9)'};
  box-shadow: 0px 4px 12px 0px rgba(13, 10, 44, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (max-width: 760px) {
    max-height: 120px;
    max-width: 100%;
    width: 100%;
    margin-bottom: 0;
  }
`;

export const FeatureIcon = styled.object`
  width: 133px;
  height: 154px;

  @media (max-width: 760px) {
    width: 70px;
  }
`;
