import Excel2PdfIcon from 'assets/converterIcons/excel_2_pdf.svg';
import {
  default as Jpg2PdfIcon,
  default as Png2PdfIcon,
} from 'assets/converterIcons/jpg_2_pdf.svg';
import Pdf2JpgIcon from 'assets/converterIcons/pdf_2_jpg.svg';
import Pdf2PngIcon from 'assets/converterIcons/pdf_2_png.svg';
import Pdf2PptxIcon from 'assets/converterIcons/pdf_2_pptx.svg';
import Pdf2WordIcon from 'assets/converterIcons/pdf_2_word.svg';
import Pdf2XlsIcon from 'assets/converterIcons/pdf_2_xls.svg';
import Pptx2PdfIcon from 'assets/converterIcons/pptx_2_pdf.svg';
import Word2PdfIcon from 'assets/converterIcons/word_2_pdf.svg';

import { EServiceType, IService } from 'ts/interfaces/services/service';

export const converterServicesList = (t: any): IService[] => {
  return [
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-word',
      name: t('service_page.pdf_to_word.name'),
      nameKey: 'service_page.pdf_to_word.name',
      title: t('service_page.pdf_to_word.title'),
      description: t('service_page.pdf_to_word.description'),
      heroFeatures: [
        {
          title: t('service_page.pdf_to_word.heroFeatures.0.title'),
          description: t('service_page.pdf_to_word.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pdf_to_word.heroFeatures.1.title'),
          description: t('service_page.pdf_to_word.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pdf_to_word.heroFeatures.2.title'),
          description: t('service_page.pdf_to_word.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.pdf_to_word.aboutSection.title'),
        items: [
          t('service_page.pdf_to_word.aboutSection.items.0'),
          t('service_page.pdf_to_word.aboutSection.items.1'),
          t('service_page.pdf_to_word.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pdf_to_word.ctaTitle'),
      meta: {
        title: t('service_page.pdf_to_word.meta.title'),
        description: t('service_page.pdf_to_word.meta.description'),
      },
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-png',
      name: t('service_page.pdf_to_png.name'),
      nameKey: 'service_page.pdf_to_png.name',
      title: t('service_page.pdf_to_png.title'),
      description: t('service_page.pdf_to_png.description'),
      heroFeatures: [
        {
          title: t('service_page.pdf_to_png.heroFeatures.0.title'),
          description: t('service_page.pdf_to_png.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pdf_to_png.heroFeatures.1.title'),
          description: t('service_page.pdf_to_png.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pdf_to_png.heroFeatures.2.title'),
          description: t('service_page.pdf_to_png.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.pdf_to_png.aboutSection.title'),
        items: [
          t('service_page.pdf_to_png.aboutSection.items.0'),
          t('service_page.pdf_to_png.aboutSection.items.1'),
          t('service_page.pdf_to_png.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pdf_to_png.ctaTitle'),
      meta: {
        title: t('service_page.pdf_to_png.meta.title'),
        description: t('service_page.pdf_to_png.meta.description'),
      },
      icon: Pdf2PngIcon,
      from: 'PDF',
      to: 'PNG',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-jpg',
      name: t('service_page.pdf_to_jpg.name'),
      nameKey: 'service_page.pdf_to_jpg.name',
      title: t('service_page.pdf_to_jpg.title'),
      description: t('service_page.pdf_to_jpg.description'),
      heroFeatures: [
        {
          title: t('service_page.pdf_to_jpg.heroFeatures.0.title'),
          description: t('service_page.pdf_to_jpg.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pdf_to_jpg.heroFeatures.1.title'),
          description: t('service_page.pdf_to_jpg.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pdf_to_jpg.heroFeatures.2.title'),
          description: t('service_page.pdf_to_jpg.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.pdf_to_jpg.aboutSection.title'),
        items: [
          t('service_page.pdf_to_jpg.aboutSection.items.0'),
          t('service_page.pdf_to_jpg.aboutSection.items.1'),
          t('service_page.pdf_to_jpg.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pdf_to_jpg.ctaTitle'),
      meta: {
        title: t('service_page.pdf_to_jpg.meta.title'),
        description: t('service_page.pdf_to_jpg.meta.description'),
      },
      icon: Pdf2JpgIcon,
      from: 'PDF',
      to: 'JPG',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-epub',
      name: t('service_page.pdf_to_epub.name'),
      nameKey: 'service_page.pdf_to_epub.name',
      title: t('service_page.pdf_to_epub.title'),
      description: t('service_page.pdf_to_epub.description'),
      heroFeatures: [
        {
          title: t('service_page.pdf_to_epub.heroFeatures.0.title'),
          description: t('service_page.pdf_to_epub.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pdf_to_epub.heroFeatures.1.title'),
          description: t('service_page.pdf_to_epub.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pdf_to_epub.heroFeatures.2.title'),
          description: t('service_page.pdf_to_epub.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.pdf_to_epub.aboutSection.title'),
        items: [
          t('service_page.pdf_to_epub.aboutSection.items.0'),
          t('service_page.pdf_to_epub.aboutSection.items.1'),
          t('service_page.pdf_to_epub.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pdf_to_epub.ctaTitle'),
      meta: {
        title: t('service_page.pdf_to_epub.meta.title'),
        description: t('service_page.pdf_to_epub.meta.description'),
      },
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'EPUB',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-excel',
      name: t('service_page.pdf_to_excel.name'),
      nameKey: 'service_page.pdf_to_excel.name',
      title: t('service_page.pdf_to_excel.title'),
      description: t('service_page.pdf_to_excel.description'),
      heroFeatures: [
        {
          title: t('service_page.pdf_to_excel.heroFeatures.0.title'),
          description: t('service_page.pdf_to_excel.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pdf_to_excel.heroFeatures.1.title'),
          description: t('service_page.pdf_to_excel.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pdf_to_excel.heroFeatures.2.title'),
          description: t('service_page.pdf_to_excel.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.pdf_to_excel.aboutSection.title'),
        items: [
          t('service_page.pdf_to_excel.aboutSection.items.0'),
          t('service_page.pdf_to_excel.aboutSection.items.1'),
          t('service_page.pdf_to_excel.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pdf_to_excel.ctaTitle'),
      meta: {
        title: t('service_page.pdf_to_excel.meta.title'),
        description: t('service_page.pdf_to_excel.meta.description'),
      },
      icon: Pdf2XlsIcon,
      from: 'PDF',
      to: 'XLS',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-pptx',
      name: t('service_page.pdf_to_pptx.name'),
      nameKey: 'service_page.pdf_to_pptx.name',
      title: t('service_page.pdf_to_pptx.title'),
      description: t('service_page.pdf_to_pptx.description'),
      heroFeatures: [
        {
          title: t('service_page.pdf_to_pptx.heroFeatures.0.title'),
          description: t('service_page.pdf_to_pptx.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pdf_to_pptx.heroFeatures.1.title'),
          description: t('service_page.pdf_to_pptx.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pdf_to_pptx.heroFeatures.2.title'),
          description: t('service_page.pdf_to_pptx.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.pdf_to_pptx.aboutSection.title'),
        items: [
          t('service_page.pdf_to_pptx.aboutSection.items.0'),
          t('service_page.pdf_to_pptx.aboutSection.items.1'),
          t('service_page.pdf_to_pptx.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pdf_to_pptx.ctaTitle'),
      meta: {
        title: t('service_page.pdf_to_pptx.meta.title'),
        description: t('service_page.pdf_to_pptx.meta.description'),
      },
      icon: Pdf2PptxIcon,
      from: 'PDF',
      to: 'PPTX',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/word-to-pdf',
      name: t('service_page.word_to_pdf.name'),
      nameKey: 'service_page.word_to_pdf.name',
      title: t('service_page.word_to_pdf.title'),
      description: t('service_page.word_to_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.word_to_pdf.heroFeatures.0.title'),
          description: t('service_page.word_to_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.word_to_pdf.heroFeatures.1.title'),
          description: t('service_page.word_to_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.word_to_pdf.heroFeatures.2.title'),
          description: t('service_page.word_to_pdf.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.word_to_pdf.aboutSection.title'),
        items: [
          t('service_page.word_to_pdf.aboutSection.items.0'),
          t('service_page.word_to_pdf.aboutSection.items.1'),
          t('service_page.word_to_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.word_to_pdf.ctaTitle'),
      meta: {
        title: t('service_page.word_to_pdf.meta.title'),
        description: t('service_page.word_to_pdf.meta.description'),
      },
      icon: Word2PdfIcon,
      from: 'DOC',
      to: 'PDF',
      availableFormats: '.doc, .docx, .rtf, .jpg, .jpeg, .txt, .png, .odt, .pages',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/png-to-pdf',
      name: t('service_page.png_to_pdf.name'),
      nameKey: 'service_page.png_to_pdf.name',
      title: t('service_page.png_to_pdf.title'),
      description: t('service_page.png_to_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.png_to_pdf.heroFeatures.0.title'),
          description: t('service_page.png_to_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.png_to_pdf.heroFeatures.1.title'),
          description: t('service_page.png_to_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.png_to_pdf.heroFeatures.2.title'),
          description: t('service_page.png_to_pdf.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.png_to_pdf.aboutSection.title'),
        items: [
          t('service_page.png_to_pdf.aboutSection.items.0'),
          t('service_page.png_to_pdf.aboutSection.items.1'),
          t('service_page.png_to_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.png_to_pdf.ctaTitle'),
      meta: {
        title: t('service_page.png_to_pdf.meta.title'),
        description: t('service_page.png_to_pdf.meta.description'),
      },
      icon: Jpg2PdfIcon,
      from: 'PNG',
      to: 'PDF',
      availableFormats: '.png, .jpeg, .jpg, .webp, .heic, .jfif, .odt',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/jpg-to-pdf',
      name: t('service_page.jpg_to_pdf.name'),
      nameKey: 'service_page.jpg_to_pdf.name',
      title: t('service_page.jpg_to_pdf.title'),
      description: t('service_page.jpg_to_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.jpg_to_pdf.heroFeatures.0.title'),
          description: t('service_page.jpg_to_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.jpg_to_pdf.heroFeatures.1.title'),
          description: t('service_page.jpg_to_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.jpg_to_pdf.heroFeatures.2.title'),
          description: t('service_page.jpg_to_pdf.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.jpg_to_pdf.aboutSection.title'),
        items: [
          t('service_page.jpg_to_pdf.aboutSection.items.0'),
          t('service_page.jpg_to_pdf.aboutSection.items.1'),
          t('service_page.jpg_to_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.jpg_to_pdf.ctaTitle'),
      meta: {
        title: t('service_page.jpg_to_pdf.meta.title'),
        description: t('service_page.jpg_to_pdf.meta.description'),
      },
      icon: Png2PdfIcon,
      from: 'JPG',
      to: 'PDF',
      availableFormats: '.jpg, .jpeg, .png, .heic, .webp, .jfif',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/epub-to-pdf',
      name: t('service_page.epub_to_pdf.name'),
      nameKey: 'service_page.epub_to_pdf.name',
      title: t('service_page.epub_to_pdf.title'),
      description: t('service_page.epub_to_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.epub_to_pdf.heroFeatures.0.title'),
          description: t('service_page.epub_to_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.epub_to_pdf.heroFeatures.1.title'),
          description: t('service_page.epub_to_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.epub_to_pdf.heroFeatures.2.title'),
          description: t('service_page.epub_to_pdf.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.epub_to_pdf.aboutSection.title'),
        items: [
          t('service_page.epub_to_pdf.aboutSection.items.0'),
          t('service_page.epub_to_pdf.aboutSection.items.1'),
          t('service_page.epub_to_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.epub_to_pdf.ctaTitle'),
      meta: {
        title: t('service_page.epub_to_pdf.meta.title'),
        description: t('service_page.epub_to_pdf.meta.description'),
      },
      icon: Pdf2WordIcon,
      from: 'EPUB',
      to: 'PDF',
      availableFormats: '.epub',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/excel-to-pdf',
      name: t('service_page.excel_to_pdf.name'),
      nameKey: 'service_page.excel_to_pdf.name',
      title: t('service_page.excel_to_pdf.title'),
      description: t('service_page.excel_to_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.excel_to_pdf.heroFeatures.0.title'),
          description: t('service_page.excel_to_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.excel_to_pdf.heroFeatures.1.title'),
          description: t('service_page.excel_to_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.excel_to_pdf.heroFeatures.2.title'),
          description: t('service_page.excel_to_pdf.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.excel_to_pdf.aboutSection.title'),
        items: [
          t('service_page.excel_to_pdf.aboutSection.items.0'),
          t('service_page.excel_to_pdf.aboutSection.items.1'),
          t('service_page.excel_to_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.excel_to_pdf.ctaTitle'),
      meta: {
        title: t('service_page.excel_to_pdf.meta.title'),
        description: t('service_page.excel_to_pdf.meta.description'),
      },
      icon: Excel2PdfIcon,
      from: 'XLS',
      to: 'PDF',
      availableFormats: '.xls, .xlsx, .csv, .txt, .rtf, .ods, .odt',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pptx-to-pdf',
      name: t('service_page.pptx_to_pdf.name'),
      nameKey: 'service_page.pptx_to_pdf.name',
      title: t('service_page.pptx_to_pdf.title'),
      description: t('service_page.pptx_to_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.pptx_to_pdf.heroFeatures.0.title'),
          description: t('service_page.pptx_to_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pptx_to_pdf.heroFeatures.1.title'),
          description: t('service_page.pptx_to_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pptx_to_pdf.heroFeatures.2.title'),
          description: t('service_page.pptx_to_pdf.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.pptx_to_pdf.aboutSection.title'),
        items: [
          t('service_page.pptx_to_pdf.aboutSection.items.0'),
          t('service_page.pptx_to_pdf.aboutSection.items.1'),
          t('service_page.pptx_to_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pptx_to_pdf.ctaTitle'),
      meta: {
        title: t('service_page.pptx_to_pdf.meta.title'),
        description: t('service_page.pptx_to_pdf.meta.description'),
      },
      icon: Pptx2PdfIcon,
      from: 'PPTX',
      to: 'PDF',
      availableFormats: '.pptx, .ppt',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/image-to-pdf',
      name: t('service_page.image_to_pdf.name'),
      nameKey: 'service_page.image_to_pdf.name',
      title: t('service_page.image_to_pdf.title'),
      description: t('service_page.image_to_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.image_to_pdf.heroFeatures.0.title'),
          description: t('service_page.image_to_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.image_to_pdf.heroFeatures.1.title'),
          description: t('service_page.image_to_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.image_to_pdf.heroFeatures.2.title'),
          description: t('service_page.image_to_pdf.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.image_to_pdf.aboutSection.title'),
        items: [
          t('service_page.image_to_pdf.aboutSection.items.0'),
          t('service_page.image_to_pdf.aboutSection.items.1'),
          t('service_page.image_to_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.image_to_pdf.ctaTitle'),
      meta: {
        title: t('service_page.image_to_pdf.meta.title'),
        description: t('service_page.image_to_pdf.meta.description'),
      },
      icon: Png2PdfIcon,
      from: 'Image',
      to: 'PDF',
      // TODO: Removed unsupported images
      // availableFormats: '.jpg, .jpeg, .png, .gif, .svg, .webp, .bmp, .eps',
      availableFormats: '.jpg, .jpeg, .png, .gif, .webp, .bmp',
    },
  ];
};