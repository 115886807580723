import { CommonTextH3 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;

  @media (max-width: 760px) {
    margin: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  width: 100%;

  @media (max-width: 760px) {
    padding-top: 40px;
    width: 100%;
    font-size: 20px;
    text-align: center;
  }
`;

export const SectionTitle = styled.h2`
  color: var(--Text-color-tittle, #212e45);
  font-family: 'Arial';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 120%;
  padding: 0 15px;
  text-align: center;

  @media (max-width: 760px) {
    font-size: 23px;
  }
`;

export const FeaturesContainer = styled.div`
  display: flex;
  margin-top: 48px;
  gap: 20px;
  max-width: 900px;
  width: 100%;
  margin: 48px 15px 0 15px;
  align-items: center;
  align-self: center;

  @media (max-width: 930px) {
    flex-direction: column;
    margin: 30px 0 0 0;
    justify-items: center;
    gap: 0;
    padding: 0 15px;
  }

  @media (max-width: 760px) {
    flex-direction: column;
    margin: 30px 0 0 0;
    justify-items: center;
    gap: 15px;
    padding: 0 15px;
  }
`;

export const FeatureItemContainer = styled.div`
  min-width: 280px;
  max-width: 420px;
  display: flex;
  flex: 1 1 0px;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);

    &:nth-child(2) {
      object {
        height: 100px;
      }
  }

  @media (max-width: 930px) {
    max-width: 500px;
  }

  @media (max-width: 760px) {
    max-width: auto;
    width: 100%;
    height: auto;
    border-radius: 10px;

      &:nth-child(2) {
        object {
          height: 70px;
        }
    }
  }
`;

export const FeatureItemImageContainer = styled.div<{ background: string }>`
  width: 100%;
  height: 180px;
  flex-shrink: 0;
  border-radius: 12px;
  background: ${(props) => props?.background || 'var(--Primary-primary-50, #3758F9)'};
  box-shadow: 0px 4px 12px 0px rgba(13, 10, 44, 0.06);
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 760px) {
    height: 120px;
    margin-bottom: 20px;
  }
`;

export const FeatureIcon = styled.object`
  width: auto;
  height: 120px;
  fill: none;

  @media (max-width: 760px) {
    width: auto;
    height: 70px;
  }
`;

export const FeatureItemLabel = styled(CommonTextH3)`
  color: var(--Text-color-tittle, #212e45);
  text-align: center;
  margin-bottom: 12px;

  font-size: 19px;
  font-style: normal;
  font-weight: 700;

  @media (max-width: 760px) {
    font-size: 19px;
    margin-bottom: 12px;
  }
`;

export const FeatureItemDescription = styled.span`
  color: var(--Text-color-tittle, #212e45);
  text-align: left;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */

  @media (max-width: 760px) {
    font-size: 16px;
  }
`;
