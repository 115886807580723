export const servicesListWithGroups = {
  convertFromPDF: [
    {
      path: '/pdf-to-word',
      name: 'service_page.pdf_to_word.name',
    },
    {
      path: '/pdf-to-png',
      name: 'service_page.pdf_to_png.name',
    },
    {
      path: '/pdf-to-jpg',
      name: 'service_page.pdf_to_jpg.name',
    },
    {
      path: '/pdf-to-epub',
      name: 'service_page.pdf_to_epub.name',
    },
    {
      path: '/pdf-to-excel',
      name: 'service_page.pdf_to_excel.name',
    },
    {
      path: '/pdf-to-pptx',
      name: 'service_page.pdf_to_pptx.name',
    },
  ],
  convertToPDF: [
    {
      path: '/word-to-pdf',
      name: 'service_page.word_to_pdf.name',
    },
    {
      path: '/png-to-pdf',
      name: 'service_page.png_to_pdf.name',
    },
    {
      path: '/jpg-to-pdf',
      name: 'service_page.jpg_to_pdf.name',
    },
    {
      path: '/epub-to-pdf',
      name: 'service_page.epub_to_pdf.name',
    },
    {
      path: '/excel-to-pdf',
      name: 'service_page.excel_to_pdf.name',
    },
    {
      path: '/pptx-to-pdf',
      name: 'service_page.pptx_to_pdf.name',
    },
  ],
  editPdf: [
    {
      path: '/edit-pdf',
      name: 'global.edit_pdf',
    },
    {
      path: '/sign-pdf',
      name: 'global.sign_pdf',
    },
    {
      path: '/delete-pages-pdf',
      name: 'global.delete_pages_pdf',
    },
    {
      path: '/merge-pdf',
      name: 'global.merge_pdf',
    },
    {
      path: '/merge-images',
      name: 'global.merge_images',
    },
    {
      path: '/split-pdf',
      name: 'global.split_pdf',
    },
    {
      path: '/compress-pdf',
      name: 'global.compress_pdf',
    },
    {
      path: '/ocr-pdf',
      name: 'global.pdf_ocr',
    },
  ],
  pdfActions: [
    {
      path: '/edit-pdf',
      name: 'global.edit_pdf',
    },
    {
      path: '/sign-pdf',
      name: 'global.sign_pdf',
    },
    {
      path: '/delete-pages-pdf',
      name: 'global.delete_pages_pdf',
    },
    { path: '/ocr-pdf', name: 'global.pdf_ocr' },
    { path: '/image-to-text', name: 'global.image_to_text' },
  ],
  forms: [
    {
      path: '/form-w9',
      name: 'global.form_w9',
    },
    {
      path: '/form-ds11',
      name: 'global.form_ds11',
    },
    {
      path: '/form-ds82',
      name: 'global.form_ds82',
    },
  ],
};
