import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCustomNavigate } from 'hooks/useCustomNavigate';
//** Selectors */
import { selectedPlanSelector } from 'data/selectors/subscriptions';

//** Components */

/** Types */
import { IPlan } from 'ts/interfaces/plan/plan';

/** Actions */
import { getFreeAccess, setSelectedPlan } from 'data/actions/subscriptions';

//** Icons */

//** Styles */
import PlanItemTestB from 'components/planItem/tests/abc-modal_sales_payment_2_2_4/planItemTestB';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { usePlans } from 'hooks/usePlans';
import { useUploadFile } from 'hooks/useUploadFile';
import { EModalTypes } from 'ts/enums/modal.types';
import {
  Container,
  Description,
  DescriptionContainer,
  DialogLeftContainer,
  DialogTitle,
  PlansContainer,
} from './styles';

const SelectPlanModalContentB: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedPlan = useSelector(selectedPlanSelector) || getJsonFomStorage('selectedPlan');
  const plans = usePlans();
  const navigate = useCustomNavigate();

  useUploadFile(true);

  useEffect(() => {
    dispatch(
      sendAnalyticEvent({
        event: 'select_plan_view',
        data: { screen_config_name: 'sales_modal_3_plans_with_buttons' },
      })
    );
  }, [dispatch]);

  const handleSelectPlan = ({
    plan,
    place,
  }: {
    plan: IPlan;
    route?: string;
    place: 'planTab' | 'button';
  }) => {
    localStorage.setItem('selectedPlan', JSON.stringify(plan));
    dispatch(setSelectedPlan(plan));
    dispatch(
      sendAnalyticEvent({
        event: 'select_plan_confirm_tap',
        data: { planName: t(plan?.name), place },
      })
    );
    handleSubmit();
  };

  useEffect(() => {
    if (!selectedPlan) {
      dispatch(setSelectedPlan(plans[1] || plans[0]));
    }
  }, [dispatch, plans, selectedPlan]);

  const handleSubmit = () => {
    dispatch(toggleModal({ type: EModalTypes.PAYMENT_MODAL, visible: true }));
  };

  const handleFreeAccess = () => {
    dispatch(sendAnalyticEvent({ event: 'give_free_access_tap' }));
    const onSuccess = () => {
      navigate('/app/dashboard');
      dispatch(
        toggleModal({
          type: EModalTypes.GET_FREE_ACCESS,
          visible: true,
          options: { status: 'success ' },
        })
      );
    };

    const onFail = () => {
      dispatch(
        toggleModal({
          type: EModalTypes.GET_FREE_ACCESS,
          visible: true,
          options: { status: 'error' },
        })
      );
    };

    dispatch(sendAnalyticEvent({ event: 'give_free_access_tap' }));
    dispatch(getFreeAccess(onSuccess, onFail));
  };

  return (
    <Container>
      <DialogLeftContainer>
        <DialogTitle>{t('choose_plan_page.title')}</DialogTitle>

        <PlansContainer>
          {plans?.map((plan: IPlan, index: number) => (
            <PlanItemTestB
              handleSelectPlan={handleSelectPlan}
              key={`plan-item-${index + 1}`}
              planData={plan}
            />
          ))}
        </PlansContainer>
        <DescriptionContainer>
          <Description>
            {t(selectedPlan?.descriptionSales, {
              currencySymbol: selectedPlan?.currencySymbol,
              fullPrice: selectedPlan?.fullPrice,
            })}
            {t('global.to_access_your_first_document_start')}{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={handleFreeAccess}
            >
              {' '}
              {t('global.click_here')}
            </span>
            {t('global.to_access_your_first_document_end')}
          </Description>
        </DescriptionContainer>
      </DialogLeftContainer>
    </Container>
  );
};

export default SelectPlanModalContentB;
