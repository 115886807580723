import MergePdfIcon from 'assets/converterIcons/merge_pdf.svg';
import { EServiceType } from 'ts/interfaces/services/service';

export const mergeImagesPageService = (t: any) => {
  return {
    serviceType: EServiceType.MERGER,
    path: '/merge-images',
    name: t('service_page.merge_images.name'),
    nameKey: 'service_page.merge_images.name',
    title: t('service_page.merge_images.title'),
    description: t('service_page.merge_images.description'),
    heroFeatures: [
      {
        title: t('service_page.merge_images.heroFeatures.0.title'),
        description: t('service_page.merge_images.heroFeatures.0.description'),
      },
      {
        title: t('service_page.merge_images.heroFeatures.1.title'),
        description: t('service_page.merge_images.heroFeatures.1.description'),
      },
      {
        title: t('service_page.merge_images.heroFeatures.2.title'),
        description: t('service_page.merge_images.heroFeatures.2.description'),
      },
    ],
    icon: MergePdfIcon,
    from: 'Image',
    to: 'PDF',
    availableFormats: '.jpg, .jpeg, .png, .gif, .bmp, .webp',
    aboutSection: {
      title: t('service_page.merge_images.aboutSection.title'),
      items: [
        t('service_page.merge_images.aboutSection.items.0'),
        t('service_page.merge_images.aboutSection.items.1'),
        t('service_page.merge_images.aboutSection.items.2'),
      ],
    },
    ctaTitle: t('service_page.merge_images.ctaTitle'),
    meta: {
      title: t('service_page.merge_images.meta.title'),
      description: t('service_page.merge_images.meta.description'),
    },
  };
};
