import { Collapse } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { toggleModal } from 'data/actions/modals';
import { quickActionsWithGroups } from 'helpers/quickActions';
import {
  FormsIcon,
  MobileActionItem,
  MobileActionLabel,
  MobileActions,
  MobileContainer,
  RightArrowBoxImage,
  VectorEditImage,
  LeftArrowBoxImage,
} from './styles';
import { sendAnalyticEvent } from 'data/actions/analytics';

const generateTabs = (t: Function, handleClickAction: Function) => [
  {
    key: '1',
    label: (
      <MobileActionLabel>
        <VectorEditImage />
        {t('global.pdf_editor')}
      </MobileActionLabel>
    ),
    children: (
      <MobileActions>
        {quickActionsWithGroups.editPdf.map((group, index) => (
          <MobileActionItem
            to={group.path}
            key={`quick-actions-edit-pdf-${index + 1}`}
            onClick={() => handleClickAction(t(group.name))}
          >
            {t(group.name)}
          </MobileActionItem>
        ))}
      </MobileActions>
    ),
  },
  {
    key: '2',
    label: (
      <MobileActionLabel>
        <RightArrowBoxImage />
        {t('global.convert_to_pdf')}
      </MobileActionLabel>
    ),
    children: (
      <MobileActions>
        {quickActionsWithGroups.convertToPDF.map((group, index) => (
          <MobileActionItem
            to={group.path}
            key={`quick-actions-convert-to-pdf-${index + 1}`}
            onClick={() => handleClickAction(t(group.name))}
          >
            {t(group.name)}
          </MobileActionItem>
        ))}
      </MobileActions>
    ),
  },
  {
    key: '3',
    label: (
      <MobileActionLabel>
        <LeftArrowBoxImage />
        {t('global.convert_from_pdf')}
      </MobileActionLabel>
    ),
    children: (
      <MobileActions>
        {quickActionsWithGroups.convertFromPDF.map((group, index) => (
          <MobileActionItem
            to={group.path}
            key={`quick-actions-convert-from-pdf-${index + 1}`}
            onClick={() => handleClickAction(t(group.name))}
          >
            {t(group.name)}
          </MobileActionItem>
        ))}
      </MobileActions>
    ),
  },
  {
    key: '4',
    label: (
      <MobileActionLabel>
        <FormsIcon />
        {t('global.forms')}
      </MobileActionLabel>
    ),
    children: (
      <MobileActions>
        {quickActionsWithGroups.forms.map((group, index) => (
          <MobileActionItem
            to={group.path}
            key={`quick-actions-forms-${index + 1}`}
            onClick={() => handleClickAction(t(group.name))}
          >
            {t(group.name)}
          </MobileActionItem>
        ))}
      </MobileActions>
    ),
  },
];

const QuickActionsMobile: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickAction = (name: string) => {
    dispatch(toggleModal({ visible: false }));
    dispatch(
      sendAnalyticEvent({ event: 'quick_actions_modal_tap', data: { features_name: name } })
    );
  };

  return (
    <MobileContainer>
      <Collapse
        style={{ width: '100%' }}
        items={generateTabs(t, handleClickAction)}
        bordered={false}
        expandIconPosition="end"
        ghost
        accordion
      />
    </MobileContainer>
  );
};

export default QuickActionsMobile;
