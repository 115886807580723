import { isShowUAEAddressSelector, isShowUSAAddressSelector } from 'data/selectors/user';
import { useSelector } from 'react-redux';

export const useLegalAddress = (): {
  legalName: string;
  legalAddress: string;
  legalCountry: string;
} => {
  // temporarily for the bank's review for all USA
  const isUAEUser = useSelector(isShowUAEAddressSelector);
  const isUSAUser = useSelector(isShowUSAAddressSelector);

  if (isUAEUser)
    return {
      legalName: 'FT SICH FZCO',
      legalAddress: 'IFZA Business Park, DDP, Building A2, 21379-001, Dubai, United Arab Emirates',
      legalCountry: 'United Arab Emirates',
    };
  else if (isUSAUser)
    return {
      legalName: 'GenDoc Solutions Limited',
      legalAddress: '7251 West Lake Mead Boulevard, Suite 300, Las Vegas, Nevada 89128, USA',
      legalCountry: 'United States',
    };
  return {
    legalName: 'XAFENIL LIMITED',
    legalAddress:
      'Petraki Giallourou 1, Tofaris Gardens, 1st floor, Office/Flat 101, Latsia, 2220 Nicosia, Cyprus',
    legalCountry: 'Republic Cyprus',
  };
};
