import { useTranslation } from 'react-i18next';

// Styles
import { Container, CtaButton, SectionTitle, Wrapper } from './styles';

interface ICtaFormSectionProps {
  handleFillForm: () => void;
  ctaTitle?: string;
  isFixedBox: boolean;
  isLoading: boolean;
}

export const CtaFormSection = ({
  ctaTitle,
  handleFillForm,
  isFixedBox,
  isLoading = false,
}: ICtaFormSectionProps) => {
  const { t } = useTranslation();

  return (
    <Container isfixedbox={isFixedBox ? 'true' : 'false'}>
      <Wrapper>
        <SectionTitle>
          {ctaTitle || 'Streamline business tasks with our PDF to Word сonverter'}
        </SectionTitle>
        <CtaButton
          activeClass="active"
          to="services-section"
          spy={true}
          smooth={true}
          offset={-10}
          duration={500}
          onClick={handleFillForm}
          disabled={isLoading}
        >
          {t('global.form_button')}
        </CtaButton>
      </Wrapper>
    </Container>
  );
};
