import { Upload } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';

import openNotification from 'components/commonNotification';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';
import useDisableScroll from 'hooks/useDisableScroll';
import useFileUploadAndConvert from 'hooks/useFileUploadAndConvert';
import { useDispatch, useSelector } from 'react-redux';
import { ENotification } from 'ts/interfaces/common/notification';

import { FILE_TYPES_NOT_FOR_EDITOR_FLOW } from 'components/uploadButtons/uploadButton';

import { OCR_MAX_PAGES_COUNT } from 'components/uploadButtons/uploadButtonOCR';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { pushMergeDocument } from 'data/actions/documents';
import { mergeDocumentsListSelector } from 'data/selectors/documents';
import { countPdfPages } from 'helpers/countPdfPages';
import { getFileFormatFromFile } from 'helpers/getFileFormatFromFile';
import { mergePageService } from 'helpers/services/servicesList';
import { validateFile } from 'helpers/validation';
import useFileUploadAndCompressEditorFlow from 'hooks/editor-flow/useFileUploadAndCompress';
import useFileUploadAndConvertEditorFlow from 'hooks/editor-flow/useFileUploadAndConvert';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import useFileUploadAndEdit from 'hooks/useFileUploadAndEdit';
import useFileUploadAndMerge from 'hooks/useFileUploadAndMerge';
import useFileUploadAndSplit from 'hooks/useFileUploadAndSplit';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { EModalTypes } from 'ts/enums/modal.types';
import { EServiceType } from 'ts/interfaces/services/service';
import { convertToPdf } from 'utils/convertToPdf';
import { formatFileSize } from 'utils/formatFileSize';
import { ModalContainer } from '../baseModal/styles';
import { Container, Title } from './styles';

const DragAndDropModal: FC = () => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const mergeDocumentsList: any = useSelector(mergeDocumentsListSelector());
  const options = useSelector(modalOptionsSelector);
  const service = options?.service;
  const isMergeImages = useMemo(() => service?.from?.toLowerCase() === 'image', [service]);
  const [filesArrayToUpload, setFilesArrayToUpload] = useState<File[]>([]);
  const { handleUploadImagesFiles } = useFileUploadAndMerge({
    service: mergePageService(t),
  });

  const isMultiplyAndMaxCount =
    service?.serviceType === EServiceType.MERGER ||
    (service?.serviceType === EServiceType.CONVERTOR &&
      location?.pathname.includes('/image-to-pdf'));

  const { handleUploadFile } = useFileUploadAndConvert({ service });
  const { handleUploadFile: handleUploadFileEditorFlow } = useFileUploadAndConvertEditorFlow({
    service,
  });
  const { handleUploadFile: handleUploadEditFile } = useFileUploadAndEdit({
    service,
  });
  const { handleUploadFile: handleUploadCompressFile } = useFileUploadAndCompressEditorFlow({
    service,
  });
  const { handleUploadFile: handleUploadSplitFile } = useFileUploadAndSplit({
    service,
  });

  const uploadFileCallback = (file: File) => {
    if (
      !getFileFormatFromFile(file) ||
      FILE_TYPES_NOT_FOR_EDITOR_FLOW.includes(getFileFormatFromFile(file)!.toLowerCase())
    ) {
      return handleUploadFile(file);
    }
    handleUploadFileEditorFlow(file);
  };

  const uploadFileConverterCallback = (file: File) => {
    if (
      !getFileFormatFromFile(file) ||
      FILE_TYPES_NOT_FOR_EDITOR_FLOW.includes(getFileFormatFromFile(file)!.toLowerCase())
    ) {
      return dispatch(
        toggleModal({
          visible: true,
          type: EModalTypes.CHOOSE_FORMAT_AND_CONVERT,
          options: { file, service },
        })
      );
    }
    handleUploadFileEditorFlow(file);
  };

  const [fileData, setFileData] = useState<(File & { uid: string }) | null>(null);
  const [countFiles, setCountFiles] = useState<number>(0);
  let sendNotification = false;

  useDisableScroll();

  const handleBeforeUpload = (
    file: (File & { uid: string }) | null,
    FileList?: File[] & { uid: string }[]
  ) => {
    let isErrorMessage = validateFile(file, t, service?.availableFormats);

    if (mergeDocumentsList?.length + FileList?.length > 15)
      isErrorMessage = t('global.upload_up_to');
    if (FileList?.length && service?.serviceType === EServiceType.MERGER)
      setCountFiles(FileList?.length);

    if (isErrorMessage || !file) {
      dispatch(toggleModal({ visible: false }));

      if (!sendNotification) {
        sendNotification = true;
        openNotification({
          message: isErrorMessage,
          type: ENotification.ERROR,
        });
        dispatch(
          sendAnalyticEvent({
            event: 'file_upload_status',
            data: {
              status: 'fail',
              place: 'main',
              errorCode: (file?.size || 0) > 100 * 1024 * 1024 ? 'max-size' : 'wrong-format',
              size: formatFileSize(file?.size || 0),
              accurate_size: (file?.size || 0) / 1000000,
              fileCounter: 1,
              file_format: `.${file?.name?.split('.')?.pop()}`,
              is_validation_error: 'true',
            },
          })
        );
        if (service?.serviceType === EServiceType.MERGER) {
          dispatch(sendAnalyticEvent({ event: 'complete_merge_error_message' }));
        }
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    // fix drag html elements on site (png, svg)
    const listener = () => {
      handleBeforeUpload(fileData);
    };

    window.addEventListener('drop', listener);

    return () => {
      window.removeEventListener('drop', listener);
    };
  });

  const handleOnDragLeave = (event: any) => {
    if (!event?.relatedTarget) dispatch(toggleModal({ visible: false }));
  };

  useEffect(() => {
    if (service?.serviceType === EServiceType.MERGER) {
      if (countFiles && mergeDocumentsList?.length === countFiles) {
        dispatch(toggleModal({ visible: false }));
        navigate('/complete-merge');
      }
    }
  }, [navigate, location, mergeDocumentsList, countFiles, dispatch, service?.serviceType]);

  const processFiles = async (file: any) => {
    setFileData(file?.file);

    if (service?.serviceType === EServiceType.OCR && file?.file?.type === 'application/pdf') {
      const pagesCount = await countPdfPages(file?.file);

      if (pagesCount > OCR_MAX_PAGES_COUNT) {
        openNotification({
          message: t('global.pages_count_error', { count: OCR_MAX_PAGES_COUNT }),
          type: ENotification.ERROR,
        });
        dispatch(toggleModal({ visible: false }));
        return;
      }
    }

    const filesToProcess = filesArrayToUpload.length > 0 ? filesArrayToUpload : [file.file];

    dispatch(
      sendAnalyticEvent({
        event: 'upload_ﬁle_tap',
        data: { method: 'drag_and_drop' },
      })
    );

    if (
      service?.serviceType === EServiceType.CONVERTOR &&
      location?.pathname.includes('/image-to-pdf') &&
      filesArrayToUpload.length > 1
    ) {
      return handleUploadImagesFiles(filesToProcess);
    }

    switch (service?.serviceType) {
      case EServiceType.PDF_CONVERTOR:
        uploadFileConverterCallback(filesToProcess[0]);
        break;
      case EServiceType.COMPRESSOR:
        handleUploadCompressFile(filesToProcess[0]);
        break;
      case EServiceType.MERGER:
        if (isMergeImages) {
          convertToPdf(file.file).then((newFile) => {
            dispatch(pushMergeDocument({ file: newFile, thumbnail: null }));
          });
        } else {
          dispatch(pushMergeDocument({ file: file?.file, thumbnail: null }));
        }
        break;
      case EServiceType.EDITOR:
        handleUploadEditFile(filesToProcess[0]);
        break;
      case EServiceType.SPLITTER:
        handleUploadSplitFile(filesToProcess[0]);
        break;
      case EServiceType.OCR:
        dispatch(
          toggleModal({
            visible: true,
            type: EModalTypes.CHOOSE_FORMAT_AND_PARSE_TEXT,
            options: { file: filesToProcess[0], service },
          })
        );
        break;
      default:
        uploadFileCallback(filesToProcess[0]);
    }

    setFilesArrayToUpload([]);
  };

  return (
    <ModalContainer onDragLeave={(event) => handleOnDragLeave(event)}>
      <Upload
        name="file"
        type="drag"
        showUploadList={false}
        multiple={isMultiplyAndMaxCount}
        maxCount={isMultiplyAndMaxCount ? 15 : 1}
        customRequest={processFiles}
        beforeUpload={(file: File & { uid: string }, FileList: File[] & { uid: string }[]) => {
          if (FileList.length > 1) {
            setFilesArrayToUpload(FileList);
          }

          return handleBeforeUpload(file, FileList);
        }}
      >
        <Container>
          <Title>{t('global.drop_your_file_here')}</Title>
        </Container>
      </Upload>
    </ModalContainer>
  );
};

export default DragAndDropModal;
