import lottie from 'lottie-web/build/player/lottie_light';
import { FC, useEffect, useRef } from 'react';

import animationData from 'assets/animations/loading-animation.json';
import useDisableScroll from 'hooks/useDisableScroll';
import { useTranslation } from 'react-i18next';
import { FileUploadingModalViewTypes } from 'services/analytics/events';
import { ModalContainer } from '../baseModal/styles';
import { Container, Description } from '../processingMergeModal/styles';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { useDispatch } from 'react-redux';

const ProcessingDocumentModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const animationRef = useRef(null);
  useDisableScroll();

  useEffect(() => {
    if (!animationRef.current || typeof window === 'undefined') return;

    lottie?.loadAnimation({
      container: animationRef?.current,
      renderer: 'svg',
      name: 'buttonHover',
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        className: 'lottie-svg-button-upload',
      },
    });

    return () => {
      lottie?.destroy();
    };
  }, []);

  useEffect(() => {
    dispatch(
      sendAnalyticEvent({
        event: 'file_uploading_modal_view',
        data: {
          type: FileUploadingModalViewTypes.convertFunnels,
        },
      })
    );
  }, [dispatch]);

  return (
    <ModalContainer>
      <Container>
        <div ref={animationRef} />
        <Description>{t('global.processing_document')}</Description>
      </Container>
    </ModalContainer>
  );
};

export default ProcessingDocumentModal;
