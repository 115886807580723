import { Upload } from 'antd';
import { FC, useState } from 'react';

import CommonButton from 'components/common/button';
import openNotification from 'components/commonNotification';
import { ENotification } from 'ts/interfaces/common/notification';
import { IService } from 'ts/interfaces/services/service';

import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import { countPdfPages } from 'helpers/countPdfPages';
import { validateFile } from 'helpers/validation';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { EModalTypes } from 'ts/enums/modal.types';
import { formatFileSize } from 'utils/formatFileSize';
import { ButtonStyles, Container, MobileButton } from '../uploadButton/styles';

export const OCR_MAX_PAGES_COUNT = 30;

const UploadButtonOCR: FC<{
  service?: IService;
  buttonText?: string;
  isFooter?: boolean;
}> = ({
  service,
  isFooter,
  buttonText,
}: {
  service?: IService;
  buttonText?: string;
  isFooter?: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);

  const handleBeforeUpload = async (file: File) => {
    const isErrorMessage = await validateUploadedFile(file);

    if (isErrorMessage) {
      openNotification({
        message: isErrorMessage,
        type: ENotification.ERROR,
      });
      dispatch(
        sendAnalyticEvent({
          event: 'file_upload_status',
          data: {
            status: 'fail',
            place: 'main',
            errorCode: (file?.size || 0) > 100 * 1024 * 1024 ? 'max-size' : 'wrong-format',
            size: formatFileSize(file?.size || 0),
            accurate_size: (file?.size || 0) / 1000000,
            fileCounter: 1,
            file_format: `.${file?.name?.split('.')?.pop()}`,
            is_validation_error: 'true',
          },
        })
      );
      return false;
    }
    return true;
  };

  const validateUploadedFile = async (file: File) => {
    const generalErrorMessage = validateFile(file, t, service?.availableFormats);

    if (!!generalErrorMessage) {
      return generalErrorMessage;
    }

    const pagesCount = file?.type === 'application/pdf' ? await countPdfPages(file) : 1;

    return pagesCount > OCR_MAX_PAGES_COUNT
      ? t('global.pages_count_error', { count: OCR_MAX_PAGES_COUNT })
      : null;
  };

  // disable drag & drop
  const handleOnDragOver = () => {
    if (!disabled) setDisabled(true);
  };

  // disable drag & drop
  const handleOnMouseOver = () => {
    if (disabled) setDisabled(false);
  };

  return (
    <Container
      onDragOver={() => handleOnDragOver()}
      onMouseOver={() => handleOnMouseOver()}
      onClick={() =>
        dispatch(
          sendAnalyticEvent({
            event: isFooter ? 'upload_ﬁle_footer_tap' : 'upload_ﬁle_tap',
          })
        )
      }
    >
      <Upload
        type="select"
        multiple={false}
        disabled={disabled}
        accept={service?.availableFormats}
        name="file"
        showUploadList={false}
        customRequest={(file: any) => {
          dispatch(
            toggleModal({
              visible: true,
              type: EModalTypes.CHOOSE_FORMAT_AND_PARSE_TEXT,
              options: { file: file.file, service },
            })
          );
        }}
        beforeUpload={handleBeforeUpload}
      >
        <CommonButton width="324px" className="desktop" style={ButtonStyles} type="primary">
          {buttonText || t('global.file_upload_button_mobile')}
        </CommonButton>

        <MobileButton className="mobile" style={ButtonStyles} type="primary">
          {buttonText || t('global.file_upload_button_mobile')}
        </MobileButton>
      </Upload>
    </Container>
  );
};

export default UploadButtonOCR;
