import FormIcon1 from 'assets/forms/formIcon1.svg';
import FormIcon2 from 'assets/forms/formIcon2.svg';
import FormIcon3 from 'assets/forms/formIcon3.svg';
import HotToIcon from 'assets/forms/howToForm.svg';
import Word2PdfIcon from 'assets/converterIcons/word_2_pdf.svg';
import BackgroundW9HeroImg from 'assets/forms/w9MainBg.png';
import BackgroundDS11HeroImg from 'assets/forms/ds11MainBg.png';
import BackgroundDS82HeroImg from 'assets/forms/ds82MainBg.png';

import formW9 from 'assets/forms/w9.pdf';
import formDS11 from 'assets/forms/ds11.pdf';
import formDS82 from 'assets/forms/ds82.pdf';
import { EServiceType, IFormService } from 'ts/interfaces/services/service';

export const formServicesList = (t: any): IFormService[] => {
  return [
    {
      serviceType: EServiceType.EDITOR,
      pagesCount: 6,
      path: '/form-w9',
      filePath: formW9,
      fileName: 'w9.pdf',
      name: t('service_page.form_w9.name'),
      nameKey: 'service_page.form_w9.name',
      title: t('service_page.form_w9.title'),
      description: t('service_page.form_w9.description'),
      caption: t('service_page.form_w9.caption'),
      from: 'PDF',
      to: 'PDF',
      mainBackground: BackgroundW9HeroImg,
      aboutSection: {
        title: t('service_page.form_w9.about_section.title'),
        items: [
          {
            label: t('service_page.form_w9.about_section.items.0.label'),
            description: t('service_page.form_w9.about_section.items.0.description'),
            icon: FormIcon1,
            color: '#3758F9'
          },
          {
            label: t('service_page.form_w9.about_section.items.1.label'),
            description: t('service_page.form_w9.about_section.items.1.description'),
            icon: FormIcon2,
            color: '#13C296'
          },
          {
            label: t('service_page.form_w9.about_section.items.2.label'),
            description: t('service_page.form_w9.about_section.items.2.description'),
            icon: FormIcon3,
            color: '#3758F9'
          }
        ],
      },
      whatIsSection: {
        title: t('service_page.form_w9.what_is_section.title'),
        text: t('service_page.form_w9.what_is_section.text')
      },
      howToSection: {
        title: t('service_page.form_w9.how_to_section.title'),
        background: "#3758F9",
        icon: HotToIcon,
        items: [
          t('service_page.form_w9.how_to_section.items.0'),
          t('service_page.form_w9.how_to_section.items.1'),
          t('service_page.form_w9.how_to_section.items.2'),
          t('service_page.form_w9.how_to_section.items.3'),
          t('service_page.form_w9.how_to_section.items.4'),
          t('service_page.form_w9.how_to_section.items.5'),
          t('service_page.form_w9.how_to_section.items.6')
        ]
      },
      whoIsSection: {
        title: t('service_page.form_w9.who_is_section.title'),
        items: [
          t('service_page.form_w9.who_is_section.items.0'),
          t('service_page.form_w9.who_is_section.items.1')
        ]
      },
      faqSection: {
        title: t('service_page.form_w9.faq_section.title'),
        items: [
          {
            title: t('service_page.form_w9.faq_section.items.0.title'),
            text: t('service_page.form_w9.faq_section.items.0.text')
          },
          {
            title: t('service_page.form_w9.faq_section.items.1.title'),
            text: t('service_page.form_w9.faq_section.items.1.text')
          },
          {
            title: t('service_page.form_w9.faq_section.items.2.title'),
            text: t('service_page.form_w9.faq_section.items.2.text')
          },
          {
            title: t('service_page.form_w9.faq_section.items.3.title'),
            text: t('service_page.form_w9.faq_section.items.3.text')
          }
        ]
      },
      contentNav: [
        t('service_page.form_w9.content_nav.0'),
        t('service_page.form_w9.content_nav.1'),
        t('service_page.form_w9.content_nav.2'),
        t('service_page.form_w9.content_nav.3')
      ],
      ctaTitles: [
        t('service_page.form_w9.cta_titles.0'),
        t('service_page.form_w9.cta_titles.1')
      ],
      meta: {
        title: t('main_page.meta.title'),
        description: t('main_page.meta.description'),
      },
      icon: Word2PdfIcon,
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.EDITOR,
      pagesCount: 6,
      path: '/form-ds11',
      filePath: formDS11,
      fileName: 'ds11.pdf',
      name: t('service_page.form_ds11.name'),
      nameKey: 'service_page.form_ds11.name',
      title: t('service_page.form_ds11.title'),
      description: t('service_page.form_ds11.description'),
      caption: t('service_page.form_ds11.caption'),
      from: 'PDF',
      to: 'PDF',
      mainBackground: BackgroundDS11HeroImg,
      aboutSection: {
        title: t('service_page.form_ds11.about_section.title'),
        items: [
          {
            label: t('service_page.form_ds11.about_section.items.0.label'),
            description: t('service_page.form_ds11.about_section.items.0.description'),
            icon: FormIcon1,
            color: '#3758F9'
          },
          {
            label: t('service_page.form_ds11.about_section.items.1.label'),
            description: t('service_page.form_ds11.about_section.items.1.description'),
            icon: FormIcon2,
            color: '#13C296'
          },
          {
            label: t('service_page.form_ds11.about_section.items.2.label'),
            description: t('service_page.form_ds11.about_section.items.2.description'),
            icon: FormIcon3,
            color: '#3758F9'
          }
        ],
      },
      whatIsSection: {
        title: t('service_page.form_ds11.what_is_section.title'),
        text: t('service_page.form_ds11.what_is_section.text')
      },
      howToSection: {
        title: t('service_page.form_ds11.how_to_section.title'),
        background: "#3758F9",
        icon: HotToIcon,
        items: [
          t('service_page.form_ds11.how_to_section.items.0'),
          t('service_page.form_ds11.how_to_section.items.1'),
          t('service_page.form_ds11.how_to_section.items.2'),
          t('service_page.form_ds11.how_to_section.items.3'),
          t('service_page.form_ds11.how_to_section.items.4'),
          t('service_page.form_ds11.how_to_section.items.5'),
          t('service_page.form_ds11.how_to_section.items.6')
        ]
      },
      whoIsSection: {
        title: t('service_page.form_ds11.who_is_section.title'),
        items: [
          t('service_page.form_ds11.who_is_section.items.0'),
          t('service_page.form_ds11.who_is_section.items.1')
        ]
      },
      faqSection: {
        title: t('service_page.form_ds11.faq_section.title'),
        items: [
          {
            title: t('service_page.form_ds11.faq_section.items.0.title'),
            text: t('service_page.form_ds11.faq_section.items.0.text')
          },
          {
            title: t('service_page.form_ds11.faq_section.items.1.title'),
            text: t('service_page.form_ds11.faq_section.items.1.text')
          },
          {
            title: t('service_page.form_ds11.faq_section.items.2.title'),
            text: t('service_page.form_ds11.faq_section.items.2.text')
          },
          {
            title: t('service_page.form_ds11.faq_section.items.3.title'),
            text: t('service_page.form_ds11.faq_section.items.3.text')
          }
        ]
      },
      contentNav: [
        t('service_page.form_ds11.content_nav.0'),
        t('service_page.form_ds11.content_nav.1'),
        t('service_page.form_ds11.content_nav.2'),
        t('service_page.form_ds11.content_nav.3')
      ],
      ctaTitles: [
        t('service_page.form_ds11.cta_titles.0'),
        t('service_page.form_ds11.cta_titles.1')
      ],
      meta: {
        title: t('main_page.meta.title'),
        description: t('main_page.meta.description'),
      },
      icon: Word2PdfIcon,
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.EDITOR,
      pagesCount: 6,
      path: '/form-ds82',
      filePath: formDS82,
      fileName: 'ds82.pdf',
      name: t('service_page.form_ds82.name'),
      nameKey: 'service_page.form_ds82.name',
      title: t('service_page.form_ds82.title'),
      description: t('service_page.form_ds82.description'),
      caption: t('service_page.form_ds82.caption'),
      from: 'PDF',
      to: 'PDF',
      mainBackground: BackgroundDS82HeroImg,
      aboutSection: {
        title: t('service_page.form_ds82.about_section.title'),
        items: [
          {
            label: t('service_page.form_ds82.about_section.items.0.label'),
            description: t('service_page.form_ds82.about_section.items.0.description'),
            icon: FormIcon1,
            color: '#3758F9'
          },
          {
            label: t('service_page.form_ds82.about_section.items.1.label'),
            description: t('service_page.form_ds82.about_section.items.1.description'),
            icon: FormIcon2,
            color: '#13C296'
          },
          {
            label: t('service_page.form_ds82.about_section.items.2.label'),
            description: t('service_page.form_ds82.about_section.items.2.description'),
            icon: FormIcon3,
            color: '#3758F9'
          }
        ],
      },
      whatIsSection: {
        title: t('service_page.form_ds82.what_is_section.title'),
        text: t('service_page.form_ds82.what_is_section.text')
      },
      howToSection: {
        title: t('service_page.form_ds82.how_to_section.title'),
        background: "#3758F9",
        icon: HotToIcon,
        items: [
          t('service_page.form_ds82.how_to_section.items.0'),
          t('service_page.form_ds82.how_to_section.items.1'),
          t('service_page.form_ds82.how_to_section.items.2'),
          t('service_page.form_ds82.how_to_section.items.3'),
          t('service_page.form_ds82.how_to_section.items.4'),
          t('service_page.form_ds82.how_to_section.items.5'),
          t('service_page.form_ds82.how_to_section.items.6')
        ]
      },
      whoIsSection: {
        title: t('service_page.form_ds82.who_is_section.title'),
        items: [
          t('service_page.form_ds82.who_is_section.items.0'),
          t('service_page.form_ds82.who_is_section.items.1')
        ]
      },
      faqSection: {
        title: t('service_page.form_ds82.faq_section.title'),
        items: [
          {
            title: t('service_page.form_ds82.faq_section.items.0.title'),
            text: t('service_page.form_ds82.faq_section.items.0.text')
          },
          {
            title: t('service_page.form_ds82.faq_section.items.1.title'),
            text: t('service_page.form_ds82.faq_section.items.1.text')
          },
          {
            title: t('service_page.form_ds82.faq_section.items.2.title'),
            text: t('service_page.form_ds82.faq_section.items.2.text')
          },
          {
            title: t('service_page.form_ds82.faq_section.items.3.title'),
            text: t('service_page.form_ds82.faq_section.items.3.text')
          }
        ]
      },
      contentNav: [
        t('service_page.form_ds82.content_nav.0'),
        t('service_page.form_ds82.content_nav.1'),
        t('service_page.form_ds82.content_nav.2'),
        t('service_page.form_ds82.content_nav.3')
      ],
      ctaTitles: [
        t('service_page.form_ds82.cta_titles.0'),
        t('service_page.form_ds82.cta_titles.1')
      ],
      meta: {
        title: t('main_page.meta.title'),
        description: t('main_page.meta.description'),
      },
      icon: Word2PdfIcon,
      availableFormats: '.pdf',
    }
  ]
};