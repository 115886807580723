import CommonButton from 'components/common/button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 1070px;
  height: 100%;
  overflow: auto;
  flex-grow: 1;
`;

export const TitleContainer = styled.div`
  background: var(--Blue-10, #ebeeff);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  border-radius: 15px;
  gap: 15px;
`;

export const DialogLeftContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  padding-left: 15px;
  padding-right: 15px;
`;
export const DialogPreviewContainer = styled.div`
  width: 45%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const PlansContainer = styled.div`
  width: 100%;
  max-width: 1215px;
  margin: 30px 0;
  display: flex;
  justify-content: center;

  @media (max-width: 760px) {
    flex-direction: column;
    padding: 0 15px;
    gap: 15px;
    margin: 10px 0;
  }
`;

export const DialogTitle = styled.div`
  font-size: 23px;
  font-weight: 900;
  line-height: 32.43px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  /* white-space: nowrap; */
`;

export const FilePreviewTitle = styled.div`
  font-size: 23px;
  font-weight: 700;
  line-height: 26.45px;
  text-align: center;
`;

export const ContinueButton = styled(CommonButton)`
  width: 100%;
  height: 53px;
  gap: var(--Radius-s, 10px);
  color: var(--White-White, #fff);
  text-align: center;
  /* Desktop/Body/Bold */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 23.4px */
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Description = styled.div`
  color: var(--50, #8496ba);
  /* Desktop/Caption/Regular */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  width: 100%;
  max-width: 770px;
  text-align: center;

  a {
    color: var(--50, #8496ba);
    /* Caption/Reg */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #3758f9;
    }
  }
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 5px;
  flex-grow: 1;
`;

export const FeatureItem = styled.span<{ fullHeight?: string }>`
  color: var(--Text-color-subtitle, #4c5e7f);
  /* Desktop/Body/Reg */

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  display: flex;
  align-items: start;
  padding-right: 15px;
  width: 100%;
  height: ${(props) => (props.fullHeight === 'true' ? '100%' : 'auto')};
`;

export const FeatureIcon = styled.img`
  margin-right: 10px;
  margin-top: 5px;
`;

export const FeatureColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  row-gap: 5px;
  max-height: 180px;
  width: 50%;
`;
