import CommonLink from 'components/common/link';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: var(--Primary-10, #ebeeff);
  padding: 30px 0 20px 0;
  align-items: center;
  align-self: stretch;

  @media (max-width: 780px) {
    padding: 30px 15px;
  }
`;

export const Logo = styled.img`
  width: 160px;
`;

export const LanguageLink = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  /* Desktop/Body/Reg */

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  cursor: pointer;
  text-decoration: none;
  text-underline-offset: 4px;
  width: fit-content;
  color: #4c5e7f;

  svg {
    position: absolute;
    right: -20px;
  }
`;

export const CopyrightContainer = styled.div`
  color: var(--Text-color-subtitle, #4c5e7f);
  /* Body/Reg */

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  text-align: center;

  @media (max-width: 888px) {
    width: 100%;
  }

  @media (max-width: 760px) {
    text-align: center;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 270px;
  width: 100%;

  @media (max-width: 560px) {
    max-width: fit-content;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
`;

export const LinkItem = styled(CommonLink)`
  width: fit-content;

  &:hover {
    opacity: 1;
  }
`;

export const Text = styled.div`
  color: var(--Text-color-subtitle, #4c5e7f) !important;
  /* Body/Reg */

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  text-decoration: none;
`;

export const LinksGroupTitle = styled.div`
  /* Body/Bold */

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: 780px) {
    img {
      display: none;
    }
  }
`;

export const DesktopContainer = styled.div`
  display: flex;
  gap: 30px;
  max-width: 1170px;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 560px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Divider = styled.div`
  max-width: 1170px;
  width: 100%;
  border-bottom: 1px solid #c9d8f4;
  margin: 20px auto;
`;
