import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Tabs } from 'antd';

import { quickActionsWithGroups } from 'helpers/quickActions';
import { toggleModal } from 'data/actions/modals';
import closeImagePath from 'assets/close-icon.svg';
import ModalLayout from '../baseModal';
import {
  Container,
  Title,
  CloseIcon,
  TabsContainer,
  ActionsContainer,
  ActionImage,
  ActionTitle,
  ActionItem,
} from './styles';
import QuickActionsMobile from './mobile';
import { sendAnalyticEvent } from 'data/actions/analytics';

const generateTabs = (t: Function, handleClickAction: Function) => [
  {
    key: '1',
    label: t('global.pdf_editor'),
    children: (
      <ActionsContainer>
        {quickActionsWithGroups.editPdf.map((group, index) => (
          <ActionItem
            to={group.path}
            key={`quick-actions-edit-pdf-${index + 1}`}
            onClick={() => handleClickAction(t(group.name))}
          >
            <ActionImage src={group.icon} alt={t(group.name)} />
            <ActionTitle>{t(group.name)}</ActionTitle>
          </ActionItem>
        ))}
      </ActionsContainer>
    ),
  },
  {
    key: '2',
    label: t('global.convert_to_pdf'),
    children: (
      <ActionsContainer>
        {quickActionsWithGroups.convertToPDF.map((group, index) => (
          <ActionItem
            to={group.path}
            key={`quick-actions-convert-to-pdf-${index + 1}`}
            onClick={() => handleClickAction(t(group.name))}
          >
            <ActionImage src={group.icon} alt={t(group.name)} />
            <ActionTitle>{t(group.name)}</ActionTitle>
          </ActionItem>
        ))}
      </ActionsContainer>
    ),
  },
  {
    key: '3',
    label: t('global.convert_from_pdf'),
    children: (
      <ActionsContainer>
        {quickActionsWithGroups.convertFromPDF.map((group, index) => (
          <ActionItem
            to={group.path}
            key={`quick-actions-convert-from-pdf-${index + 1}`}
            onClick={() => handleClickAction(t(group.name))}
          >
            <ActionImage src={group.icon} alt={t(group.name)} />
            <ActionTitle>{t(group.name)}</ActionTitle>
          </ActionItem>
        ))}
      </ActionsContainer>
    ),
  },
  {
    key: '4',
    label: t('global.forms'),
    children: (
      <ActionsContainer>
        {quickActionsWithGroups.forms.map((group, index) => (
          <ActionItem
            to={group.path}
            key={`quick-actions-forms-${index + 1}`}
            onClick={() => handleClickAction(t(group.name))}
          >
            <ActionImage src={group.icon} alt={t(group.name)} />
            <ActionTitle>{t(group.name)}</ActionTitle>
          </ActionItem>
        ))}
      </ActionsContainer>
    ),
  },
];

const QuickActionsModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'quick_actions_modal_view' }));
  }, [dispatch]);

  const handleClickAction = (name: string) => {
    dispatch(toggleModal({ visible: false }));
    dispatch(
      sendAnalyticEvent({ event: 'quick_actions_modal_tap', data: { features_name: name } })
    );
  };

  const handleClose = () => {
    dispatch(sendAnalyticEvent({ event: 'quick_actions_close_tap' }));
    dispatch(toggleModal({ visible: false }));
  };

  return (
    <ModalLayout>
      <Container>
        <CloseIcon onClick={handleClose} src={closeImagePath} alt="" />
        <Title>{t('dashboard_page.quick_actions')}</Title>

        <QuickActionsMobile />

        <TabsContainer>
          <Tabs defaultActiveKey="1" items={generateTabs(t, handleClickAction)} />
        </TabsContainer>
      </Container>
    </ModalLayout>
  );
};

export default QuickActionsModal;
