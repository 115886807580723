import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Actions
import { sendAnalyticEvent } from 'data/actions/analytics';

// Hooks
import { useCustomNavigate } from 'hooks/useCustomNavigate';

// Assets
import LogoImage from 'assets/logo-white.svg';

// Interfaces
import { IFormService } from 'ts/interfaces/services/service';

// Styles
import {
  Caption,
  CaptionContainer,
  Container,
  Content,
  HeroButton,
  HeroHeader,
  HeroHeaderLayout,
  HeroMainContent,
  HeroSecondaryHeader,
  Logo,
  LogoContainer,
} from './styles';

interface IMainHeroFormSectionProps {
  service: IFormService;
  handleFillForm: () => void;
  isLoading: boolean;
}

const MainHeroFormSection = ({
  service,
  handleFillForm,
  isLoading = false,
}: IMainHeroFormSectionProps) => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const { t } = useTranslation();

  const handleNavigateMain = () => {
    navigate('/');
    dispatch(sendAnalyticEvent({ event: 'main_page_tap' }));
  };

  return (
    <Container background={service?.mainBackground}>
      <Content>
        <LogoContainer>
          <Logo
            src={LogoImage}
            onClick={handleNavigateMain}
            alt="TheBestPDF logo"
            width="159px"
            height="29px"
          />
        </LogoContainer>
        <HeroMainContent>
          <HeroHeaderLayout>
            <HeroHeader>{service?.title}</HeroHeader>
          </HeroHeaderLayout>
          <HeroSecondaryHeader>{service?.description}</HeroSecondaryHeader>
          <HeroButton
            activeClass="active"
            to="services-section"
            spy={true}
            smooth={true}
            offset={-10}
            duration={500}
            onClick={handleFillForm}
            disabled={isLoading}
          >
            {t('global.form_button')}
          </HeroButton>
        </HeroMainContent>
        <CaptionContainer>
          <Caption>{service?.caption}</Caption>
        </CaptionContainer>
      </Content>
    </Container>
  );
};

export default MainHeroFormSection;
