import { FC } from 'react';

import CommonLink from 'components/common/link';
import { servicesListWithGroups } from 'helpers/servicesListWithGroups';
import { useTranslation } from 'react-i18next';
import MobileFooterHeader from './mobile';
import {
  ColumnContainer,
  Container,
  DesktopContainer,
  LinksGroup,
  LinksGroupTitle,
  Text,
} from './styles';
import { useDispatch } from 'react-redux';
import { sendAnalyticEvent } from 'data/actions/analytics';

const FooterHeader: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickFeature = (name: string) => {
    dispatch(
      sendAnalyticEvent({
        event: 'footer_features_tap',
        data: { feature_name: name },
      })
    );
    window.scrollTo(0, 0);
  };

  return (
    <Container>
      <DesktopContainer>
        <ColumnContainer>
          <LinksGroupTitle>{t('global.edit_pdf')}</LinksGroupTitle>
          {servicesListWithGroups.editPdf.map((item: any, index) =>
            item?.disabled ? (
              <Text key={`footer-text-header-to-link-${index + 1}`}>{t(item.name)}</Text>
            ) : (
              <CommonLink
                size="18px"
                type="secondary"
                key={`footer-header-to-link-${index + 1}`}
                onClick={() => handleClickFeature(t(item.name))}
                to={item.path}
              >
                {t(item.name)}
              </CommonLink>
            )
          )}
        </ColumnContainer>
        <ColumnContainer>
          <LinksGroupTitle>{t('global.convert_from_pdf')}</LinksGroupTitle>
          <LinksGroup>
            {servicesListWithGroups.convertFromPDF.map((item, index) => (
              <CommonLink
                size="18px"
                type="secondary"
                key={`footer-link-header-from-link-${index + 1}`}
                onClick={() => handleClickFeature(t(item.name))}
                to={item.path}
              >
                {t(item.name)}
              </CommonLink>
            ))}
          </LinksGroup>
        </ColumnContainer>
        <ColumnContainer>
          <LinksGroupTitle>{t('global.convert_to_pdf')}</LinksGroupTitle>
          <LinksGroup>
            {servicesListWithGroups.convertToPDF.map((item, index) => (
              <CommonLink
                size="18px"
                type="secondary"
                key={`footer-convert-header-to-link-${index + 1}`}
                onClick={() => handleClickFeature(t(item.name))}
                to={item.path}
              >
                {t(item.name)}
              </CommonLink>
            ))}
          </LinksGroup>
        </ColumnContainer>
        <ColumnContainer>
          <LinksGroupTitle>{t('global.forms')}</LinksGroupTitle>
          {servicesListWithGroups.forms.map((item, index) => (
            <CommonLink
              size="18px"
              type="secondary"
              key={`footer-from-link-${index + 1}`}
              onClick={() => handleClickFeature(t(item.name))}
              to={item.path}
            >
              {t(item.name)}
            </CommonLink>
          ))}
        </ColumnContainer>
      </DesktopContainer>
      <MobileFooterHeader />
    </Container>
  );
};

export default FooterHeader;
