import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  min-height: 295px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  max-height: 90dvh;

  @media (max-width: 760px) {
    width: 100%;
    height: auto;
    margin: 0 15px;
  }
`;

export const StyledModalContainer = styled(Container)`
  background: rgb(255, 255, 255);
  width: 1060px;
  max-width: 90vw;
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

export const Title = styled.div`
  color: var(--Text-color-tittle, #212e45);
  text-align: center;
  /* Desktop/Heading/H4 */

  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 29.9px */
`;

export const Description = styled.div`
  color: var(--Text-color-subtitle, #4c5e7f);
  text-align: center;
  /* Desktop/Body/Reg */

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  margin: 8px 0 20px 0;
`;

export const BigStarImage = styled.img`
  margin: 10px 0;
`;

export const DialogHeadRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const CloseIcon = styled.img`
  cursor: pointer;
`;

export const ModalHeader = styled.h3`
  font-size: 23px;
  font-weight: 900;
  line-height: 32.43px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;
