import dayjs from 'dayjs';

export const getFileFormatFromFile = (files: File | File[]) => {
  let file = Array.isArray(files) ? files[0] : files;
  if (!file) return null;

  const formatMatch = file.name.match(/\.[0-9a-z]+$/i);

  if (formatMatch) {
    return formatMatch[0];
  }

  return null;
};

export const formatTimeMoment = ({
  time,
  locale = 'en',
  format = 'MMMM DD, YYYY',
}: {
  time: string;
  locale?: string;
  format?: string;
}) => {
  return dayjs(time).locale(locale).format(format);
};

export const getFileNameWithoutFormatFromFile = (file: File) => {
  const fileFormat = getFileFormatFromFile(file);

  return file.name.replace(new RegExp(fileFormat + '$'), '');
};
