import { Collapse } from 'antd';
import { FC } from 'react';

import { CollapseProps } from 'antd';
import imgArrowPath from 'assets/arrow-vector-icon.svg';
import { servicesListWithGroups } from 'helpers/servicesListWithGroups';
import { useTranslation } from 'react-i18next';
import { CustomExpandIcon, Divider, MobileContainer, MobileLinkItem } from './styles';
import { useDispatch } from 'react-redux';
import { sendAnalyticEvent } from 'data/actions/analytics';

const MobileFooterHeader: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleNavigate = (name: string) => {
    window.scrollTo(0, 0);
    dispatch(
      sendAnalyticEvent({
        event: 'footer_features_tap',
        data: { feature_name: name },
      })
    );
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: t('global.edit_pdf'),
      children: servicesListWithGroups.editPdf.map((item, index) => (
        <MobileLinkItem
          key={`collapse-footer-header-mobile-edit-pdf-${index + 1}`}
          onClick={() => handleNavigate(t(item.name))}
          to={item.path}
        >
          {t(item.name)}
        </MobileLinkItem>
      )),
    },
    {
      key: '2',
      label: t('global.convert_from_pdf'),
      children: servicesListWithGroups.convertFromPDF.map((item, index) => (
        <MobileLinkItem
          key={`collapse-footer-header-mobile-from-link-${index + 1}`}
          onClick={() => handleNavigate(t(item.name))}
          to={item.path}
        >
          {t(item.name)}
        </MobileLinkItem>
      )),
    },
    {
      key: '3',
      label: t('global.convert_to_pdf'),
      children: servicesListWithGroups.convertToPDF.map((item, index) => (
        <MobileLinkItem
          key={`collapse-convert-pdf-mobile-to-link-${index + 1}`}
          onClick={() => handleNavigate(t(item.name))}
          to={item.path}
        >
          {t(item.name)}
        </MobileLinkItem>
      )),
    },
    {
      key: '4',
      label: t('global.forms'),
      children: servicesListWithGroups.forms.map((item, index) => (
        <MobileLinkItem
          key={`collapse-form-link-${index + 1}`}
          onClick={() => handleNavigate(t(item.name))}
          to={item.path}
        >
          {t(item.name)}
        </MobileLinkItem>
      )),
    },
  ];

  return (
    <MobileContainer>
      {items.map((item, index) => (
        <div key={`collapse-footer-${item.label}-group-${index + 1}`}>
          <Collapse
            expandIcon={({ isActive }) => (
              <CustomExpandIcon $is_active={`${isActive}`} src={imgArrowPath} alt="" />
            )}
            items={[item]}
            bordered={false}
            expandIconPosition="end"
            ghost
          />
          {index !== items.length - 1 && <Divider />}
        </div>
      ))}
    </MobileContainer>
  );
};

export default MobileFooterHeader;
