import styled from 'styled-components';
import { CommonTextH2 } from 'components/common/styles';
import { Button } from 'react-scroll';

export const Container = styled.div<{ isfixedbox?: string }>`
  width: 100%;
  display: flex;
  opacity: ${(props) => (props?.isfixedbox === 'true' ? '1' : '0')};
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  transition: opacity 200ms ease-out;
  z-index: 100;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 90px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background: var(--Primary-10, #ebeeff);
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);

  @media (max-width: 760px) {
    padding: 40px 15px;
    gap: 15px;
    flex-direction: column;
    height: 120px;

    div {
      height: 50px;
    }
  }
`;

export const SectionTitle = styled(CommonTextH2)`
  text-align: center;
  font-family: 'Arial' !important;
  font-weight: 700;
  font-size: 23px;

  @media (max-width: 760px) {
    font-size: 16px;
    font-style: normal;
  }
`;

export const CtaButton = styled(Button)`
  padding: 15px 30px;
  border-radius: 5px;
  min-width: 140px;
  background: var(--Primary-Color, #3758f9);
  color: var(--White-White, #fff);
  border: unset;

  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;

  svg {
    color: #fff !important;
  }

  &:hover {
    background: var(--background-button-primary-hover, #2443d7);
  }

  @media (max-width: 760px) {
    font-size: 16px;
  }
`;
