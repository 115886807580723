import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import CommonButton from 'components/common/button';
import ModalLayout from 'components/modals/baseModal';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';
import { useModalSalesPayment } from 'hooks/growthBook/useModalSalesPayment';
import { useTranslation } from 'react-i18next';
import { TABLET_SCREEN_SIZE_WIDTH } from 'ts/constants/general';
import { LocalStorageProperties } from 'ts/enums/growthbook';
import { EModalTypes } from 'ts/enums/modal.types';
import { CloseIcon } from '../baseModal/styles';
import { getErrorText, getErrorTitle, getIconPath } from './getContentFunctions';
import { Container, Description, Image, ImageContainer, Title } from './styles';

const PaymentErrorModal: FC = () => {
  const dispatch = useDispatch();
  const options = useSelector(modalOptionsSelector);
  const { t } = useTranslation();
  const modalSalesPAymentABC = useModalSalesPayment();
  const modalSalesPAymentABCVariant = useMemo(() => {
    if (window.innerWidth <= TABLET_SCREEN_SIZE_WIDTH) {
      return LocalStorageProperties.MODAL_SALES_PAYMENT_A;
    }

    return modalSalesPAymentABC;
  }, [modalSalesPAymentABC]);

  useEffect(() => {
    dispatch(
      sendAnalyticEvent({
        event: 'payment_declined_modal_view',
        data: {
          error_code: options?.errorCode || '',
          error_message: options?.errorMessage || '',
        },
      })
    );
  }, []); // eslint-disable-line

  const handleTryAgain = () => {
    dispatch(
      sendAnalyticEvent({
        event: 'payment_declined_confirm_tap',
      })
    );
    if (typeof options?.handleTryAgain === 'function') options?.handleTryAgain();
    window.scrollTo(0, 0);
    if (
      modalSalesPAymentABCVariant === LocalStorageProperties.MODAL_SALES_PAYMENT_B ||
      modalSalesPAymentABCVariant === LocalStorageProperties.MODAL_SALES_PAYMENT_C
    ) {
      dispatch(toggleModal({ type: EModalTypes.PAYMENT_MODAL, visible: true }));
    } else {
      dispatch(toggleModal({ visible: false }));
    }
  };

  const customHandleKeyPress = () => {
    handleTryAgain();
  };

  return (
    <ModalLayout customHandleKeyPress={customHandleKeyPress}>
      <Container>
        <CloseIcon onClick={handleTryAgain} src={closeImagePath} alt="" />
        <ImageContainer>
          <Image alt="" src={getIconPath(options?.errorCode)} />
        </ImageContainer>
        <Title>{getErrorTitle(options?.errorCode, t)}</Title>
        <Description>{getErrorText(options?.errorCode, t)}</Description>
        <CommonButton type="primary" onClick={handleTryAgain}>
          {t('global.try_again')}
        </CommonButton>
      </Container>
    </ModalLayout>
  );
};

export default PaymentErrorModal;
