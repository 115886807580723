import Pdf2WordIcon from 'assets/converterIcons/pdf_2_word.svg';
import { EServiceType, IService } from 'ts/interfaces/services/service';
import { mergeImagesPageService } from './mergeImagesService';
import { mergePageService } from './mergePageService';

export const editorServicesList = (t: any): IService[] => {
  return [
    {
      serviceType: EServiceType.EDITOR,
      path: '/edit-pdf',
      name: t('service_page.edit_pdf.name'),
      nameKey: 'service_page.edit_pdf.name',
      title: t('service_page.edit_pdf.title'),
      description: t('service_page.edit_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.edit_pdf.heroFeatures.0.title'),
          description: t('service_page.edit_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.edit_pdf.heroFeatures.1.title'),
          description: t('service_page.edit_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.edit_pdf.heroFeatures.2.title'),
          description: t('service_page.edit_pdf.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('service_page.edit_pdf.aboutSection.title'),
        items: [
          t('service_page.edit_pdf.aboutSection.items.0'),
          t('service_page.edit_pdf.aboutSection.items.1'),
          t('service_page.edit_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.edit_pdf.ctaTitle'),
      meta: {
        title: t('service_page.edit_pdf.meta.title'),
        description: t('service_page.edit_pdf.meta.description'),
      },
    },
    {
      serviceType: EServiceType.EDITOR,
      path: '/fill-pdf',
      name: t('service_page.fill_pdf.name'),
      nameKey: 'service_page.fill_pdf.name',
      title: t('service_page.fill_pdf.title'),
      description: t('service_page.fill_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.fill_pdf.heroFeatures.0.title'),
          description: t('service_page.fill_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.fill_pdf.heroFeatures.1.title'),
          description: t('service_page.fill_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.fill_pdf.heroFeatures.2.title'),
          description: t('service_page.fill_pdf.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('service_page.fill_pdf.aboutSection.title'),
        items: [
          t('service_page.fill_pdf.aboutSection.items.0'),
          t('service_page.fill_pdf.aboutSection.items.1'),
          t('service_page.fill_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.fill_pdf.ctaTitle'),
      meta: {
        title: t('service_page.fill_pdf.meta.title'),
        description: t('service_page.fill_pdf.meta.description'),
      },
    },
    {
      serviceType: EServiceType.EDITOR,
      path: '/delete-pages-pdf',
      nameKey: 'service_page.delete_pages_pdf.name',
      name: t('service_page.delete_pages_pdf.name'),
      title: t('service_page.delete_pages_pdf.title'),
      description: t('service_page.delete_pages_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.delete_pages_pdf.heroFeatures.0.title'),
          description: t('service_page.delete_pages_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.delete_pages_pdf.heroFeatures.1.title'),
          description: t('service_page.delete_pages_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.delete_pages_pdf.heroFeatures.2.title'),
          description: t('service_page.delete_pages_pdf.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('service_page.delete_pages_pdf.aboutSection.title'),
        items: [
          t('service_page.delete_pages_pdf.aboutSection.items.0'),
          t('service_page.delete_pages_pdf.aboutSection.items.1'),
          t('service_page.delete_pages_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.delete_pages_pdf.ctaTitle'),
      meta: {
        title: t('service_page.delete_pages_pdf.meta.title'),
        description: t('service_page.delete_pages_pdf.meta.description'),
      },
    },
    {
      serviceType: EServiceType.PDF_CONVERTOR,
      path: '/pdf-converter',
      name: t('service_page.pdf_converter.name'),
      nameKey: 'service_page.pdf_converter.name',
      title: t('service_page.pdf_converter.title'),
      description: t('service_page.pdf_converter.description'),
      heroFeatures: [
        {
          title: t('service_page.pdf_converter.heroFeatures.0.title'),
          description: t('service_page.pdf_converter.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pdf_converter.heroFeatures.1.title'),
          description: t('service_page.pdf_converter.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pdf_converter.heroFeatures.2.title'),
          description: t('service_page.pdf_converter.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      // TODO: Removed unsupported images
      // availableFormats:
      //   '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpg, .jpeg, .png, .gif, .svg, .webp, .bmp, .eps',
      availableFormats:
        '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpg, .jpeg, .png, .gif, .webp, .bmp, .odt, .heic, .txt, .rtf, .html, .ods, .pages, .epub, .jfif',
      aboutSection: {
        title: t('service_page.pdf_converter.aboutSection.title'),
        items: [
          t('service_page.pdf_converter.aboutSection.items.0'),
          t('service_page.pdf_converter.aboutSection.items.1'),
          t('service_page.pdf_converter.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pdf_converter.ctaTitle'),
      meta: {
        title: t('service_page.pdf_converter.meta.title'),
        description: t('service_page.pdf_converter.meta.description'),
      },
    },
    {
      serviceType: EServiceType.COMPRESSOR,
      path: '/compress-pdf',
      name: t('service_page.compress_pdf.name'),
      nameKey: 'service_page.compress_pdf.name',
      title: t('service_page.compress_pdf.title'),
      description: t('service_page.compress_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.compress_pdf.heroFeatures.0.title'),
          description: t('service_page.compress_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.compress_pdf.heroFeatures.1.title'),
          description: t('service_page.compress_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.compress_pdf.heroFeatures.2.title'),
          description: t('service_page.compress_pdf.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('service_page.compress_pdf.aboutSection.title'),
        items: [
          t('service_page.compress_pdf.aboutSection.items.0'),
          t('service_page.compress_pdf.aboutSection.items.1'),
          t('service_page.compress_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.compress_pdf.ctaTitle'),
      meta: {
        title: t('service_page.compress_pdf.meta.title'),
        description: t('service_page.compress_pdf.meta.description'),
      },
    },
    {
      serviceType: EServiceType.EDITOR,
      path: '/sign-pdf',
      name: t('service_page.sign_pdf.name'),
      nameKey: 'service_page.sign_pdf.name',
      title: t('service_page.sign_pdf.title'),
      description: t('service_page.sign_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.sign_pdf.heroFeatures.0.title'),
          description: t('service_page.sign_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.sign_pdf.heroFeatures.1.title'),
          description: t('service_page.sign_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.sign_pdf.heroFeatures.2.title'),
          description: t('service_page.sign_pdf.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('service_page.sign_pdf.aboutSection.title'),
        items: [
          t('service_page.sign_pdf.aboutSection.items.0'),
          t('service_page.sign_pdf.aboutSection.items.1'),
          t('service_page.sign_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.sign_pdf.ctaTitle'),
      meta: {
        title: t('service_page.sign_pdf.meta.title'),
        description: t('service_page.sign_pdf.meta.description'),
      },
    },
    mergePageService(t),
    mergeImagesPageService(t),
    {
      serviceType: EServiceType.SPLITTER,
      path: '/split-pdf',
      name: t('service_page.split_pdf.name'),
      nameKey: 'service_page.split_pdf.name',
      title: t('service_page.split_pdf.title'),
      description: t('service_page.split_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.split_pdf.heroFeatures.0.title'),
          description: t('service_page.split_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.split_pdf.heroFeatures.1.title'),
          description: t('service_page.split_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.split_pdf.heroFeatures.2.title'),
          description: t('service_page.split_pdf.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('service_page.split_pdf.aboutSection.title'),
        items: [
          t('service_page.split_pdf.aboutSection.items.0'),
          t('service_page.split_pdf.aboutSection.items.1'),
          t('service_page.split_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.split_pdf.ctaTitle'),
      meta: {
        title: t('service_page.split_pdf.meta.title'),
        description: t('service_page.split_pdf.meta.description'),
      },
    },
    {
      serviceType: EServiceType.OCR,
      path: '/image-to-text',
      name: t('service_page.image_to_text.name'),
      nameKey: 'service_page.image_to_text.name',
      title: t('service_page.image_to_text.title'),
      description: t('service_page.image_to_text.description'),
      heroFeatures: [
        {
          title: t('service_page.image_to_text.heroFeatures.0.title'),
          description: t('service_page.image_to_text.heroFeatures.0.description'),
        },
        {
          title: t('service_page.image_to_text.heroFeatures.1.title'),
          description: t('service_page.image_to_text.heroFeatures.1.description'),
        },
        {
          title: t('service_page.image_to_text.heroFeatures.2.title'),
          description: t('service_page.image_to_text.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf, .png, .jpg, .bmp, .jpeg, .tiff, .gif, .djvu',
      aboutSection: {
        title: t('service_page.image_to_text.aboutSection.title'),
        items: [
          t('service_page.image_to_text.aboutSection.items.0'),
          t('service_page.image_to_text.aboutSection.items.1'),
          t('service_page.image_to_text.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.image_to_text.ctaTitle'),
      meta: {
        title: t('service_page.image_to_text.meta.title'),
        description: t('service_page.image_to_text.meta.description'),
      },
    },
    {
      serviceType: EServiceType.OCR,
      path: '/ocr-pdf',
      name: t('service_page.pdf_ocr.name'),
      nameKey: 'service_page.pdf_ocr.name',
      title: t('service_page.pdf_ocr.title'),
      description: t('service_page.pdf_ocr.description'),
      heroFeatures: [
        {
          title: t('service_page.pdf_ocr.heroFeatures.0.title'),
          description: t('service_page.pdf_ocr.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pdf_ocr.heroFeatures.1.title'),
          description: t('service_page.pdf_ocr.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pdf_ocr.heroFeatures.2.title'),
          description: t('service_page.pdf_ocr.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf, .png, .jpg, .bmp, .jpeg, .tiff, .gif, .djvu',
      aboutSection: {
        title: t('service_page.pdf_ocr.aboutSection.title'),
        items: [
          t('service_page.pdf_ocr.aboutSection.items.0'),
          t('service_page.pdf_ocr.aboutSection.items.1'),
          t('service_page.pdf_ocr.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pdf_ocr.ctaTitle'),
      meta: {
        title: t('service_page.pdf_ocr.meta.title'),
        description: t('service_page.pdf_ocr.meta.description'),
      },
    },
  ];
};
