import { getFileNameWithoutFormatFromFile } from 'helpers/getFileFormatFromFile';
import jsPDF from 'jspdf';

export const convertToPdf = async (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const img = document.createElement('img');
      img.onload = () => {
        const pdf = new jsPDF({
          orientation: img.width > img.height ? 'l' : 'p',
          unit: 'px',
          format: [img.width, img.height],
        });
        pdf.addImage(img, file.type, 0, 0, img.width, img.height);
        const pdfBlob = pdf.output('blob');
        const pdfFile = new File([pdfBlob], `${getFileNameWithoutFormatFromFile(file)}.pdf`, {
          type: 'application/pdf',
        });
        resolve(pdfFile);
      };
      img.onerror = reject;
      img.src = e.target?.result as string;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const createFileList = (files: File[]): FileList => {
  const dataTransfer = new DataTransfer();
  files.forEach((file) => dataTransfer.items.add(file));
  return dataTransfer.files;
};
