import {
  compressDocument,
  convertDocument,
  parseTextFromDocument,
  splitDocument,
} from 'data/actions/documents';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EServiceType } from 'ts/interfaces/services/service';
import { useCustomNavigate } from './useCustomNavigate';

export const useUploadFile = (clearSearch?: boolean) => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const dataToConvert = localStorage.getItem('dataToConvert');

    if (!parsed?.['upload-file'] || !dataToConvert) {
      return;
    }

    try {
      const data = JSON.parse(dataToConvert);

      if (!data?.file) {
        return;
      }

      switch (data.serviceType) {
        case EServiceType.COMPRESSOR:
          dispatch(compressDocument(data?.file));
          break;
        case EServiceType.SPLITTER:
          dispatch(splitDocument(data?.file));
          break;
        case EServiceType.OCR:
          dispatch(parseTextFromDocument(data?.file, data?.to));
          break;
        case EServiceType.EDITOR:
        case EServiceType.FORMS:
          break;
        default:
          dispatch(convertDocument(data?.file));
      }
      if (clearSearch) {
        navigate(window.location.pathname);
      }
    } catch (err) {}
  }, []); // eslint-disable-line
};
