import { CommonTextH2 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-height: 100%;
  overflow: auto;
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FeatureItem = styled.span`
  color: var(--Text-color-subtitle, #4c5e7f);
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
  display: flex;
  align-items: start;
  padding-right: 15px;
`;

export const FeatureIcon = styled.img`
  margin-right: 10px;
  margin-top: 2px;
`;

export const AdditionalPaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  gap: 5px;
`;

export const NortonContainer = styled.div`
  display: flex;
  margin: 0px 0;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  color: var(--Text-secondary, #354562);
  /* Caption/Reg */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 21.44px */

  img {
    margin-right: 6px;
  }
`;

export const Description = styled.div`
  color: var(--50, #8496ba);

  /* Mobile/Caption/Regular */

  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 13.2px */

  a {
    color: var(--50, #8496ba);
    /* Caption/Reg */

    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 21.44px */
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #3758f9;
    }
  }
`;

export const GoBackButton = styled.button`
  background: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: var(--Text-color-subtitle, #4c5e7f);
  display: flex;
  cursor: pointer;
  gap: 5px;
  border: none;
  svg {
    rotate: 180deg;

    path {
      fill: var(--Text-color-subtitle, #4c5e7f);
    }
  }
`;

export const SelectedPlanData = styled.div`
  width: 50%;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
  color: var(--Text-color-tittle, #212e45);
  background-color: #f4f6ff;
  border-radius: 20px;
  padding: 20px;
`;

export const SelectedPlanName = styled.span`
  font-size: 23px;
  font-weight: 700;
  line-height: 26.45px;
  text-align: left;
`;

export const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
`;

export const Price = styled(CommonTextH2)`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 23px;
  font-weight: 900;
  line-height: 36.4px;
  text-align: right;
`;
