import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

// Interfaces
import { IFormService } from 'ts/interfaces/services/service';

// Components
import Footer from 'components/footer';
import FooterHeader from 'components/footerHeader';
import MainHeroFormSection from 'sections/heroFormPage';
import FormContent from './parts/content';
import { CtaFormSection } from './parts/cta/fullpage';
import FeaturesSection from './parts/features';

// Actions
import { sendAnalyticEvent } from 'data/actions/analytics';

// Hooks
import useFormUploadAndEdit from 'hooks/useFormUploadAndEdit';

// Styles
import { toggleModal } from 'data/actions/modals';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { EModalTypes } from 'ts/enums/modal.types';
import { parseQuery } from 'utils/getQueryObject';
import { Container } from './styles';

interface IFormServicePageProps {
  service: IFormService;
}

export const FormServicePage = ({ service }: IFormServicePageProps) => {
  const dispatch = useDispatch();
  const { handleUploadFile, handleEditDocument } = useFormUploadAndEdit({ service });
  const location = useLocation();

  const [isFixedBox, setIsFixedBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(
      sendAnalyticEvent({
        event: 'landing_page_view',
        data: {
          ...parseQuery(window.location.href),
        },
      })
    );
  }, [location, dispatch]);

  const handleFillForm = () => {
    setIsLoading(true);
    dispatch(toggleModal({ type: EModalTypes.PROCESSING_DOCUMENT, visible: true }));
    dispatch(
      sendAnalyticEvent({
        event: 'features_tap',
        data: {
          features_name: service?.path?.replace('/', '') || '',
          method: 'click',
        },
      })
    );

    if (service?.filePath) {
      const uploadForm = async () => {
        const response = await fetch(service.filePath); // Fetch the file
        if (!response.ok) {
          throw new Error('Failed to fetch file');
        }
        const blob = await response.blob();
        handleUploadFile(
          new File([blob], service?.fileName || 'form.pdf', {
            lastModified: new Date().getTime(),
            type: 'application/pdf',
          })
        );
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      };
      uploadForm();
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const dataToConvert = localStorage.getItem('dataToConvert');

    if (parsed?.['upload-file'] && dataToConvert) {
      try {
        const data = JSON.parse(dataToConvert);

        handleEditDocument(data?.file, true);
      } catch (err) {}
    }
  }, []); // eslint-disable-line

  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{service?.meta?.title}</title>
        <meta name="description" content={service?.meta?.description} />
      </Helmet>
      <MainHeroFormSection
        service={service}
        handleFillForm={handleFillForm}
        isLoading={isLoading}
      />
      <FeaturesSection service={service} />
      <CtaFormSection
        ctaTitle={service?.ctaTitles?.[0]}
        handleFillForm={handleFillForm}
        isFixedBox={isFixedBox}
        isLoading={isLoading}
      />
      <FormContent service={service} setIsFixedBox={setIsFixedBox} />
      <FooterHeader />
      <Footer />
    </Container>
  );
};
