import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px 20px 30px 20px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-height: 95dvh;

  @media (max-width: 760px) {
    width: 100%;
    height: auto;
    margin: 0 15px;
  }
`;

export const Title = styled.div`
  color: var(--Text-color-tittle, #212e45);
  text-align: center;
  /* Desktop/Heading/H4 */

  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 29.9px */
`;

export const Description = styled.div`
  color: var(--Text-color-subtitle, #4c5e7f);
  text-align: center;
  /* Desktop/Body/Reg */

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  margin: 8px 0 20px 0;
`;

export const BigStarImage = styled.img`
  margin: 10px 0;
`;
